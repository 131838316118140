import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import icon_logo from "../../assets/images/logo.png";

const Logo = ({ className }) => {
	return (
		<NavLink to="/" className={className}>
			<h1 className={`${className}__title`}>
				<img className={`${className}__img`} src={icon_logo} alt="" />
				BabyCourse
			</h1>
		</NavLink>
	);
};
Logo.propType = {
	className: PropTypes.string,
};
export default Logo;
