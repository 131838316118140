import React from "react";
import Loader from "react-loader-spinner";
import "./style.scss";

const LoaderCompanent = () => {
	return (
		<div className="loader-wrap">
			<Loader type="Puff" color="#00BFFF" height={50} width={50} />
		</div>
	);
};

export default LoaderCompanent;
