import PropType from "prop-types";
import React from "react";

const Textarea = ({ label, className, value, onChange }) => {
  return (
    <div className={className ? `${className} 'textarea'` : 'textarea'}>
      <label className="textarea__label">{label}</label>
      <div className="textarea__inner">
        <textarea
          rows="50"
          className="textarea__text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        ></textarea>
      </div>
    </div>
  );
};
Textarea.defaultProp = {
  label: "",
  value: "",
  className : "",
  onChange: () => null,
};
Textarea.propType = {
  label: PropType.string,
  value: PropType.string,
  className:PropType.string,
  onChange: PropType.func,
};
export default Textarea;
