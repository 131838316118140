import { TEXT } from "../actionTypes";

export const textAction = (action) => {
	return {
		type: TEXT,
		title: action.title,
		text: action.text,
		video: action.video,
		category: action.category,
		viewYear: action.viewYear,
		link: action.link,
	};
};
