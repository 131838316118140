import axios from "axios";
import { localUrl, url } from "../api/config.http.json";

const funcGet = async (path, token) => {
	const res = await axios({
		url: `${url}/${path}`,
		method: "get",
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return res;
};
export default funcGet;
