import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu/PageInnerMenu";
import { Button, InputText, Message, TitlePage } from "../../fields";
import Dushboard from "../../layout/Dushboard/Dushboard";
import { filesMenu } from "../../data/dataMunu.json";
import "./style.scss";
import Loader from "react-loader-spinner";
import ListSearch from "../../common/ListSearch";
import { useDispatch, useSelector } from "react-redux";
import { allCategoryAction } from "../../../redux/actions/allCategiryAction";
import { uploadFileAction } from "../../../redux/actions/uploadFileAction";
import FileReaderBlock from "../../fileReaderBlock";
import {
	funcCreate,
	funcDelete,
	funcEdit,
	funcGetById,
} from "../../../controllers";
import { initUploadFile } from "../../../redux/reducers/uploadFile";
import { useHistory } from "react-router";

const Files = ({ location }) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const { uploadFile, allcategory } = useSelector((state) => state);

	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [isFilesPdf, setFilesPdf] = useState([]);
	const [selectItems, setSlectItems] = useState([uploadFile.category]);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(allCategoryAction({ token }));
	}, []);

	const handleBenefits = (item) => {
		!selectItems.includes(item)
			? setSlectItems((selectItems) => [...selectItems, item])
			: setSlectItems((selectItems) => selectItems.filter((el) => el !== item));
	};

	const process = (message, status) => {
		status ? setStatus(true) : setStatus(false);
		setLoader(false);
		setMessage(message);
	};

	const saveHandler = async () => {
		const formData = new FormData();
		formData.append("title", uploadFile.title);
		formData.append("file", isFilesPdf[0]);
		formData.append("category", selectItems[0]);

		try {
			setLoader(true);
			const res = await funcCreate(
				formData,
				"fileload",
				token,
				"multipart/form-data"
			);
			process(res.data.message, res.status);
			if (res.status === 201) {
				process("Успешно сохранено", true);
				resetData();
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			setLoader(false);
		}
	};
	const editHandler = async () => {
		const formData = new FormData();
		formData.append("title", uploadFile.title);
		formData.append(
			"file",
			isFilesPdf.length > 0 ? isFilesPdf[0] : uploadFile.file
		);
		formData.append("category", selectItems[0]);
		try {
			setLoader(true);
			const res = await funcEdit(
				formData,
				"fileload",
				token,
				location.state.id,
				"multipart/form-data"
			);
			process("Успешно сохранено", true);
			if (res.status === 200) {
				process("Успешно сохранено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			setLoader(false);
		}
	};
	const cancelingHandler = async () => {
		resetData();
		history.goBack();
	};
	const deleteHandler = async () => {
		setLoader(true);
		const res = await funcDelete("fileload", token, location.state.id);
		if (res.status === 200) {
			process("Успешно удалено", true);
			resetData();
			setTimeout(() => {
				history.goBack();
			}, 1500);
		}
	};

	// ======== reset =======

	const resetData = () => {
		dispatch(uploadFileAction(initUploadFile));
		setFilesPdf([]);
		setSlectItems([]);
		setLoader(false);
	};
	console.log(uploadFile);
	return (
		<Dushboard>
			{location.state !== null && location.state.edit ? (
				<div className="wrapper-edit-button">
					<Button
						battonType="success-button"
						className="course-control"
						click={() => editHandler()}>
						Сохранить
					</Button>
					<Button
						battonType="light-button"
						className="course-control"
						click={() => cancelingHandler()}>
						Отменить
					</Button>
					<Button
						battonType="dark-button"
						className="course-control"
						click={() => deleteHandler()}>
						Удалить
					</Button>
				</div>
			) : (
				<PageInnerMenu data={filesMenu} />
			)}

			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					<div className="norms">
						{isStatus ? (
							<Message message={isMessage} status={isStatus} />
						) : null}
						<TitlePage tag="h2" title="Файлы" />
						<div className="row">
							<div className="col-6 col-l-12 col--m">
								<InputText
									label={"Заголовок"}
									value={uploadFile.title}
									onChange={(val) =>
										dispatch(uploadFileAction({ ...uploadFile, title: val }))
									}
								/>
								<FileReaderBlock
									loaded={
										isFilesPdf.length > 0 || uploadFile.file !== ""
											? true
											: false
									}
									text="Загрузите файл"
									uploadHandler={setFilesPdf}
								/>
								<div className="select-wrap">
									<ListSearch
										label="Категории "
										selectedItems={selectItems}
										data={allcategory.items}
										onChange={handleBenefits}
									/>
								</div>
								<div className="music__button-wrap">
									{location.state !== null && location.state.edit ? null : (
										<Button
											battonType="success-button"
											className="course-control"
											click={() => saveHandler()}>
											Сохранить
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};

export default Files;
