import PropTypes from "prop-types";
import React from "react";
import "./style.scss";
import editor from "../../../assets/images/editor.png";

const Image = ({ src, maxWidth, click }) => {
	return (
		<div
			className="image"
			style={{
				maxWidth: `${maxWidth}px`,
				height: `${(maxWidth / 16) * 9}px`,
			}}>
			<img className="image__img" src={src} />
			{click ? (
				<img className="image__editor" src={editor} onClick={click} />
			) : null}
		</div>
	);
};

Image.defaultProps = {
	src: [],
	maxWidth: 200,
};
Image.propTypes = {
	src: PropTypes.any,
	maxWidth: PropTypes.number,
};
export default Image;
