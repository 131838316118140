export const AUTH = "AUTH";
export const USER = "USER";
export const COURSE = "COURSE";
export const COURSES_BY_DAYS = "COURSES_BY_DAYS";
export const LECTURE_BY_DAYS = "LECTURE_BY_DAYS";
export const ALL_COURSES = "ALL_COURSES";
export const CAT = "CAT";
export const ALLCATEGORY = "ALLCATEGORY";
export const SETCATEGORY = "SETCATEGORY";
export const BENEFITS = "BENEFITS";
export const ALL_BENEFITS = "ALL_BENEFITS";
export const BENEFITS_UPDATE = "BENEFITS_UPDATE";
export const LESSON = "LESSON";
export const ALL_LESSON = "ALL_LESSON";
export const MUSIC = "MUSIC";
export const ALL_MUSIC = "ALL_MUSIC";
export const TEXT = "TEXT";
export const ALL_TEXT = "ALL_TEXT";
export const NORMS = "NORMS";
export const ALL_NORMS = "ALL_NORMS";
export const STATE_MENU = "STATE_MENU";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_ALL_FILE = "UPLOAD_ALL_FILE";
export const EXPERTS = "EXPERTS";
export const ALL_EXPERTS = "ALL_EXPERTS";
export const COURSES_PAGE = "COURSES_PAGE";
export const ALL_COURSES_PAGE = "ALL_COURSES_PAGE";

// =========== admin data ============

export const GET_CHILDREN = "GET_CHILDREN";
