import { ALL_COURSES } from "../actionTypes";

export const initAllCourses = {
	items: [],
};

export const allcourses = (state = initAllCourses, action) => {
	switch (action.type) {
		case ALL_COURSES:
			return {
				...state,
				items: action.items,
			};

		default:
			return state;
	}
};
