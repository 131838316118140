import { BENEFITS } from "../actionTypes";

export const benefitsAction = (action) => {
	return {
		type: BENEFITS,
		title: action.title,
		description: action.description,
		image: action.image,
		video: action.video,
		link: action.link,
		category: action.category,
		viewerMonth : action.viewerMonth
	};
};
