import React from "react";

import "./style.scss";

const DushboardTabs = ({ children }) => {
  return (
    <div className="tabs">
      <div className="row">{children}</div>
    </div>
  );
};
export default DushboardTabs;
