import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu";
import { Button, InputText, Message, Textarea, TitlePage } from "../../fields";
import Dushboard from "../../layout/Dushboard";
import { menuMusic } from "../../data/dataMunu.json";
import "./style.scss";
import Loader from "react-loader-spinner";
import ReactFileReader from "react-file-reader";
import ListSelect from "../../common/ListSelect";
import { useDispatch, useSelector } from "react-redux";
import { funcCreate, funcDelete, funcEdit } from "../../../controllers";
import { musicAction } from "../../../redux/actions/musicAction";
import music_icon from "../../../assets/svg/music.svg";
import { initMusic } from "../../../redux/reducers/music";
import { useHistory } from "react-router-dom";
import { allCategoryAction } from "../../../redux/actions/allCategiryAction";
import ListSearch from "../../common/ListSearch";

const Music = ({ location }) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const { allcategory, music } = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [uploadFile, setUploadFile] = useState(false);
	const [selectItems, setSlectItems] = useState(music.category);
	const [visible, setVisible] = useState(false);
	const [isFiles, setFiles] = useState({
		base64: "",
		fileList: {},
	});

	useEffect(() => {
		if (allcategory.items.length === 0) {
			dispatch(allCategoryAction({ token }));
		}
	}, []);

	useEffect(() => {
		setSlectItems(music.category);
	}, [music]);

	const process = (message, status) => {
		status ? setStatus(true) : setStatus(false);
		setLoader(false);
		setMessage(message);
	};

	const uploadHandler = (file) => {
		setUploadFile(true);
		setFiles(file);
	};

	const handleCategory = (item) => {
		!selectItems.includes(item)
			? setSlectItems((selectItems) => [...selectItems, item])
			: setSlectItems((selectItems) => selectItems.filter((el) => el !== item));
	};

	const saveHandler = async () => {
		const formData = new FormData();
		formData.append("title", music.title);
		formData.append("audio", isFiles[0]);
		formData.append("text", music.text);
		formData.append("category", JSON.stringify(selectItems));
		formData.append("viewYear", music.viewYear);
		formData.append("link", music.link);
		try {
			setLoader(true);
			const res = await funcCreate(
				formData,
				"melody",
				token,
				"multipart/form-data"
			);
			process(res.data.message, res.status);
			if (res.status === 201) {
				process("Успешно сохранено", true);
				resetData();
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			setLoader(false);
		}
	};

	const editHandler = async () => {
		const formData = new FormData();
		formData.append("title", music.title);
		formData.append("audio", uploadFile ? isFiles[0] : music.audio);
		formData.append("text", music.text);
		formData.append("category", JSON.stringify(selectItems));
		formData.append("viewYear", music.viewYear);
		formData.append("link", music.link);

		try {
			setLoader(true);
			const res = await funcEdit(
				formData,
				"melody",
				token,
				location.state.id,
				"multipart/form-data"
			);
			process(res.data.message, res.status);
			if (res.status === 200) {
				process("Успешно сохранено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			setLoader(false);
		}
	};
	const cancelingHandler = () => {
		resetData();
		history.goBack();
	};

	const deleteHandler = async () => {
		setLoader(true);
		const res = await funcDelete("melody", token, location.state.id);
		if (res.status === 200) {
			process("Успешно удалено", true);
			resetData();
			setTimeout(() => {
				history.goBack();
			}, 1500);
		}
	};

	const resetData = () => {
		dispatch(musicAction(initMusic));
		setFiles({
			base64: "",
			fileList: {},
		});
		setLoader(false);
		setUploadFile(false);
	};

	return (
		<Dushboard>
			{location.state !== null && location.state.edit ? (
				<div className="wrapper-edit-button">
					<Button
						battonType="success-button"
						className="course-control"
						click={() => editHandler()}>
						Сохранить
					</Button>
					<Button
						battonType="light-button"
						className="course-control"
						click={() => cancelingHandler()}>
						Отменить
					</Button>
					<Button
						battonType="dark-button"
						className="course-control"
						click={() => deleteHandler()}>
						Удалить
					</Button>
				</div>
			) : (
				<PageInnerMenu data={menuMusic} />
			)}

			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					<div className="music">
						{isStatus ? (
							<div className="lesson__message">
								<Message message={isMessage} status={isStatus} />
							</div>
						) : null}
						<TitlePage tag="h2" title="Музыка" />
						<div className="row">
							<div className="col-6 col-l-12 col--m">
								<InputText
									label="Название мелодии"
									placeholder="введите название"
									name="title-misic"
									type="text"
									value={music.title}
									onChange={(val) => {
										process("", false);
										dispatch(musicAction({ ...music, title: val }));
									}}
								/>
								<div className="music__file-reader">
									<ReactFileReader
										fileTypes={[".mp3"]}
										handleFiles={uploadHandler}>
										<Button className="image-block" battonType="success-button">
											Загрузите мелодию
										</Button>
									</ReactFileReader>
									{uploadFile ? (
										<img className="music__icon-upload" src={music_icon} />
									) : null}
								</div>
								<Textarea
									label={"Текст мелодии"}
									value={music.text}
									onChange={(val) =>
										dispatch(musicAction({ ...music, text: val }))
									}
								/>
								<InputText
									label="Ссылка на мелодию"
									placeholder="https://"
									name="title-link"
									type="text"
									value={music.link}
									onChange={(val) =>
										dispatch(musicAction({ ...music, link: val }))
									}
								/>
								<div className="music-wrap-list">
									<ListSearch
										label="Категории"
										isVisible={visible}
										selectedItems={selectItems}
										data={allcategory.items.filter(
											(i) => i.typeCategory === "Мелодии"
										)}
										onShow={() => setVisible(!visible)}
										onChange={handleCategory}
									/>
								</div>

								<div className="music__button-wrap">
									{location.state !== null && location.state.edit ? null : (
										<Button
											battonType="success-button"
											className="course-control"
											click={() => saveHandler()}>
											Сохранить
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default Music;
