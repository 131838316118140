import { COURSES_PAGE } from "../actionTypes";

export const initCoursesPage = {
	title: "",
	image: "",
	viewerYear: "",
	price: "",
	bill: true,
	description: "",
	expertsList: [],
	category: [],
};

export const courses = (state = initCoursesPage, action) => {
	switch (action.type) {
		case COURSES_PAGE:
			return {
				...state,
				title: action.title,
				image: action.image,
				viewerYear: action.viewerYear,
				price: action.price,
				bill: action.bill,
				description: action.description,
				expertsList: action.expertsList,
				category: action.category,
			};

		default:
			return state;
	}
};
