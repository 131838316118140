import React, { useMemo } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import icon_program from "../../../assets/images/icon_program.png";
import icon_benefits from "../../../assets/images/icon-benefits.png";
import icon_category from "../../../assets/images/icon-category.png";
import icon_course from "../../../assets/images/icon-course.png";
import icon_courses from "../../../assets/images/courses.png";
import icon_experts from "../../../assets/images/experts.png";
import icon_lesson from "../../../assets/images/icon-lesson.png";
import icon_musik from "../../../assets/images/icon-musik.png";
import icon_norms from "../../../assets/images/icon-norms.png";
import icon_text from "../../../assets/images/icon-text.png";
import icon_files from "../../../assets/images/icon_files.png";
import icon_open from "../../../assets/svg/open.svg";
import icon_close from "../../../assets/svg/close.svg";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { stateMenuAction } from "../../../redux/actions/stateMenuAction";
const menu = [
	{
		title: "Программа",
		src: icon_program,
		route: "/course-order",
	},
	{
		title: "Категории",
		src: icon_category,
		route: "/category",
	},
	{
		title: "Пособия",
		src: icon_benefits,
		route: "/benefits",
	},
	{
		title: "Упражнения",
		src: icon_course,
		route: "/course",
	},
	{
		title: "Эксперты",
		src: icon_experts,
		route: "/expert",
	},
	{
		title: "Курсы",
		src: icon_courses,
		route: "/courses-page",
	},
	{
		title: "Уроки",
		src: icon_lesson,
		route: "/lesson",
	},
	{
		title: "Музыка",
		src: icon_musik,
		route: "/music",
	},
	{
		title: "Текст",
		src: icon_text,
		route: "/text",
	},
	{
		title: "Нормы развития",
		src: icon_norms,
		route: "/norms",
	},
	{
		title: "Файлы",
		src: icon_files,
		route: "/files",
	},
];

const LeftMenu = () => {
	const location = useRouteMatch();
	const dispatch = useDispatch();
	const { stateMenu } = useSelector((state) => state);

	const itemMenu = useMemo(() => {
		return menu.map((item, index) => (
			<li
				key={`${index}`}
				className={
					location.path === item.route
						? "menu__item menu__item--active"
						: "menu__item"
				}>
				<NavLink to={item.route} className="menu__link">
					<img className="menu__icon" src={item.src} />
					{item.title}
				</NavLink>
			</li>
		));
	}, [menu]);

	const itemMenuMobile = useMemo(() => {
		return menu.map((item, index) => (
			<li
				key={`${index}`}
				className={
					location.path === item.route
						? "menu__item-close menu__item--active"
						: "menu__item-close"
				}>
				<NavLink
					to={item.route}
					className="menu__link menu__link--mobile menu__link--close">
					<img className="menu__item--close menu__icon" src={item.src} />
					<span className="menu__item--close menu__tooltip">{item.title}</span>
				</NavLink>
			</li>
		));
	}, [menu]);
	return (
		<div
			className={
				stateMenu.state ? "left-sidebar left-sidebar--close" : "left-sidebar"
			}>
			<div className="left-sidebar__header"></div>
			{stateMenu.state ? (
				<nav className="nav">
					<ul className="menu">{itemMenuMobile}</ul>
					<span
						className="menu__btn menu__btn--close"
						onClick={() => dispatch(stateMenuAction(false))}>
						<img src={icon_open} />
					</span>
				</nav>
			) : (
				<nav className="nav">
					<ul className="menu">{itemMenu}</ul>
					<span
						className="menu__btn"
						onClick={() => dispatch(stateMenuAction(true))}>
						<img src={icon_close} />
						Закрыть
					</span>
				</nav>
			)}
		</div>
	);
};
export default LeftMenu;
