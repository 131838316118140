import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import "../../styles/fields/inputSelectCustom.scss";

const InputSelectCustom = ({ data, label, className, visible, onChange }) => {

	const [isOption, setOption] = useState({
		title: data.length > 0 ? data[0].title : "",
		type: data.length > 0 ? data[0].typeCategory : "",
		color: data.length > 0 ? data[0].color : "",
	});
	const [isVisible, setVisible] = useState(false);
	const handler = (item) => {
		setOption(item);
		onChange(item);
		setVisible(!isVisible);
	};
	let option = [];

	const renderOption = useMemo(() => {
		return data.map((item, idx) => {
			return (
				<li
					key={idx}
					className="custom-select__option"
					onClick={() => handler(item)}>
					<span className="custom-select__title" style={{ color: item.color }}>
						{item.title}
					</span>
					<span className="custom-select__type">{item.typeCategory}</span>
				</li>
			);
		});
	}, [option]);
	return (
		<div
			className={
				!isVisible
					? `custom-select ${className}`
					: `custom-select ${className} custom-select--collapse`
			}>
			<label
				className={
					className !== "" ? `${className}__label` : "custom-select__label"
				}>
				{label}
			</label>
			<div
				className={
					className !== "" ? `${className}__select` : "custom-select__select"
				}
				onClick={() => setVisible(!isVisible)}>
				<span
					className={
						className !== ""
							? `${className}__first-option`
							: "custom-select__first-option"
					}>
					<span
						className="custom-select__title"
						style={{ color: isOption.color }}>
						{isOption.title}
					</span>
					<span className="custom-select__type">{isOption.type}</span>
				</span>
				<ul className="custom-select__list">{renderOption}</ul>
			</div>
		</div>
	);
};
InputSelectCustom.defaultProps = {
	data: [],
	label: "",
	isVisible: false,
	className: "",
	onChange: () => null,
};
InputSelectCustom.propTypes = {
	data: PropTypes.array,
	label: PropTypes.string,
	isVisible: PropTypes.bool,
	className: PropTypes.string,
	onChange: PropTypes.func,
};
export default InputSelectCustom;
