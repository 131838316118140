import { NORMS } from "../actionTypes";

export const normAction = (action) => {
	return {
		type: NORMS,
		title: action.title,
		viewMonth: action.viewMonth,
		category: action.category,
	};
};
