import { COURSES_BY_DAYS } from "../actionTypes";

export const initCourseByDay = {
    items: []
}

export const CourseByDays = (state = initCourseByDay, action) => {
    switch (action.type) {
        case COURSES_BY_DAYS:
            return {
                ...state,
                items: action.items
            }
        default: return state
    }
}