import axios from "axios";
import { url } from "../api/config.http.json";

const saveOrder = async (data, path, token) => {
	const res = await axios({
		url: `${url}/${path}/update-sort`,
		method: "patch",
		data: data,
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return res;
};
export default saveOrder;
