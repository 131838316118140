import React from 'react';
import {useSelector} from "react-redux";
import {useParams} from 'react-router-dom';
import {TitlePage} from "../../fields";
import Dushboard from "../../layout/Dushboard";
import './style.scss';

const ChildrenProfile = () => {
    let {id} = useParams();
    console.log(id)
    const {childrens} = useSelector(state => state)
    const currentChildren = childrens?.items.filter(item => item.parentLink === id).pop()

    const birthday = currentChildren && JSON.parse(currentChildren?.birthday)
    const achievements = currentChildren && JSON.parse(currentChildren.achievements)
    const doneLessons = achievements && achievements.coursesData.filter(item => item.done > 0);
    console.log(doneLessons)
    return (
        <Dushboard>
            <div className="col-12">
                <TitlePage title={currentChildren.name}/>
                <div className="children-profile">
                    <p className="children-profile__text"><span
                        className="children-profile__span">Имя ребенка:</span> {currentChildren.name}</p>
                    <p className="children-profile__text"><span className="children-profile__span">День рождения ребенка:</span> {birthday.day}.{birthday.month}.{birthday.year}
                    </p>
                    <p className="children-profile__text"><span
                        className="children-profile__span">Ведет приложение:</span> {currentChildren.parent}</p>
                </div>
            </div>
        </Dushboard>
    );
};

export default ChildrenProfile;
