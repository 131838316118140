import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useRouteMatch } from "react-router";
import { Editor } from "../../base/images/Editor";
import { Button } from "../../fields";
import "./style.scss";

const List = ({ data, hendleEditor }) => {
	const location = useRouteMatch();

	const renderList = data.map((item, idx) => {
		return (
			<li key={`${item._id}-${idx}`} className="list__item">
				{"image" in item && (
					<div
						className={
							location.path === "/all-courses" ||
							location.path === "/all-lesson"
								? "list__item--box list__item--box-img"
								: "list__item--box list__item--box-icon"
						}>
						<img className="list__image" src={item.image} />
					</div>
				)}

				<div className="list__item--box list__item--box-title">
					<span
						className="list__title"
						style={"color" in item ? { color: item.color } : null}>
						{item.title || item.name}
					</span>
				</div>
				<div className="list__item--box">
					{"typeCategory" in item && (
						<span className="list__type">
							<span className="list__type--title">Тип:</span>
							{item.typeCategory}
						</span>
					)}
					<Button className="list-editor" click={() => hendleEditor(item._id)}>
						<Editor width="22" />
					</Button>
				</div>
			</li>
		);
	});

	return <ul className="list">{renderList}</ul>;
};
List.defaultProps = {
	data: [],
};
List.propTypes = {
	data: PropTypes.array,
};
export default List;
