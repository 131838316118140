import PropTypes from "prop-types";
import React, { useState } from "react";

const RadioInput = ({ label, name, className, initSelected, onChange }) => {
	const [select, setSelect] = useState(initSelected);
	const onChangeSelect = async (id) => {
		setSelect(id);
		onChange(id);
	};
	return (
		<div
			className={
				className !== ""
					? `${className} radio-input radio-input`
					: "radio-input"
			}>
			<input
				type="radio"
				name={name}
				className={
					className !== ""
						? `${className}__input radio-input__input`
						: "radio-input__input"
				}
				id={label}
				value={label}
				onChange={(e) => onChangeSelect(e.target.value)}
			/>
			<label
				className={
					className !== ""
						? `${className}__label radio-input__label`
						: "radio-input__label"
				}>
				{label}
			</label>
		</div>
	);
};
RadioInput.defaultProps = {
	label: "",
	name: "",
	className: "",
	isChecked: false,
	onChange: () => null,
};
RadioInput.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	isChecked: PropTypes.bool,
	onChange: PropTypes.func,
};
export default RadioInput;
