import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { coursesPageMenu } from "../../data/dataMunu.json";
import Loader from "react-loader-spinner";
import { Message } from "../../fields";
import List from "../../common/list";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { funcGet, funcGetById } from "../../../controllers";
import { allCoursesPageAction } from "../../../redux/actions/allCoursesPageAction";
import { coursesAction } from "../../../redux/actions/coursesAction";

const AllCoursesPage = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const dispatch = useDispatch();
	const history = useHistory();

	const { courses, allCoursesPage } = useSelector((state) => state);
	const [isMessage, setMessage] = useState("");
	const [dataSearch, setDataSearch] = useState();

	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();

	useEffect(() => {
		getAll();
	}, []);

	const getAll = async () => {
		const res = await funcGet("courses", token);
		if (res.status > 400) {
			afterFetch(false);
		}
		setDataSearch(res.data);
		dispatch(allCoursesPageAction(res.data));
	};

	const handleEditor = async (id) => {
		try {
			const res = await funcGetById("courses", id, token);

			dispatch(
				coursesAction({
					...courses,
					title: res.data.title,
					image: res.data.image,
					viewerYear: res.data.viewerYear,
					price: res.data.price,
					bill: res.data.bill,
					description: res.data.description,
					expertsList: res.data.expertsList,
					category: res.data.category,
				})
			);

			if (res.status === 200) {
				history.push("./courses-page", {
					edit: true,
					id: id,
					path: "/courses-page",
				});
			}
		} catch (error) {}
	};

	const afterFetch = (status) => {
		if (status === true) {
			setLoader(false);
			setStatus(true);
			setMessage("Успешно сохранено.");
		} else {
			setLoader(false);
			setStatus(false);
			setMessage("Что то пошло не так, попробуйте позже.");
		}
		//dispatch(allCoursesPageAction(initAllCourses));
	};

	const getResult = (result) => {
		setDataSearch(result);
	};
	return (
		<Dushboard>
			<PageInnerMenu
				data={coursesPageMenu}
				filterData={allCoursesPage.items}
				getResult={getResult}
			/>
			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					{isStatus ? (
						<div className="lesson__message">
							<Message message={isMessage} status={isStatus} />
						</div>
					) : null}

					<div className="all-courses">
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{allCoursesPage.items.length > 0 ? (
										<h3 className="list-category__title">
											Все курсы{" "}
											<span className="list-category__title--span">
												количество {allCoursesPage.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все курсы
										</h3>
									)}
								</div>
								<List data={dataSearch} hendleEditor={handleEditor} />
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};

export default AllCoursesPage;
