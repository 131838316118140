import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { funcGet, funcGetById } from "../../../controllers";
import { allExpertsAction } from "../../../redux/actions/allExpertsAction";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { expertsMenu } from "../../data/dataMunu.json";
import Loader from "react-loader-spinner";
import { Message } from "../../fields";
import { initAllExperts } from "../../../redux/reducers/allExperts";
import List from "../../common/list";
import { expertAction } from "../../../redux/actions/expertsAction";
import "./style.scss";

const AllExperts = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const dispatch = useDispatch();
	const history = useHistory();

	const { allexperts, expert } = useSelector((state) => state);
	const [isMessage, setMessage] = useState("");
	const [dataSearch, setDataSearch] = useState([]);

	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();

	useEffect(() => {
		getAll();
	}, []);

	const getAll = async () => {
		const res = await funcGet("experts", token);

		if (res.status > 400) {
			afterFetch(false);
		}
		setDataSearch(res.data);
		dispatch(allExpertsAction(res.data));
	};

	const handleEditor = async (id) => {
		try {
			const res = await funcGetById("expert", id, token);
			if (res.status === 200) {
				dispatch(
					expertAction({
						...expert,
						title: res.data.title,
						lastName: res.data.lastName,
						skills: res.data.skills,
						avatar: res.data.avatar,
						image: res.data.image,
					})
				);
				history.push("./expert", { edit: true, id: id, path: "/expert" });
			}
		} catch (error) {}
	};

	const afterFetch = (status) => {
		if (status === true) {
			setLoader(false);
			setStatus(true);
			setMessage("Успешно сохранено.");
		} else {
			setLoader(false);
			setStatus(false);
			setMessage("Что то пошло не так, попробуйте позже.");
		}
		dispatch(allExpertsAction(initAllExperts));
	};

	const getResult = (result) => {
		setDataSearch(result);
	};

	return (
		<Dushboard>
			<PageInnerMenu
				data={expertsMenu}
				filterData={allexperts.items}
				getResult={getResult}
			/>
			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					{isStatus ? (
						<div className="lesson__message">
							<Message message={isMessage} status={isStatus} />
						</div>
					) : null}

					<div className="content all-experts">
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{allexperts.items.length > 0 ? (
										<h3 className="list-category__title">
											Все эксперты{" "}
											<span className="list-category__title--span">
												количество {allexperts.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все эксперты
										</h3>
									)}
								</div>
								<List data={dataSearch} hendleEditor={handleEditor} />
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};

export default AllExperts;
