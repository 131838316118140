import PropTypes from "prop-types";
import React from "react";

const Message = ({ message, status }) => {
	return (
		<div className="message">
			<p
				className={
					status
						? "message__text message__text--success"
						: "message__text message__text--error"
				}>
				{message}
			</p>
		</div>
	);
};

Message.defaultProps = {
	message: "",
	status: true,
};
Message.propTypes = {
	message: PropTypes.string,
	status: PropTypes.bool,
};
export default Message;
