import { ALLCATEGORY, SETCATEGORY } from "../actionTypes";

const initState = {
	items: [],
};

export const allcategory = (state = initState, action) => {
	switch (action.type) {
		case ALLCATEGORY:
			return {
				...state,
				items: action.items,
			};
		case SETCATEGORY:
			return {
				...state,
				items: [...state.items, action.items],
			};
		default:
			return state;
	}
};
