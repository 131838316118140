import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { menuLesson } from "../../data/dataMunu.json";
import "./style.scss";
import Loader from "react-loader-spinner";
import { Message } from "../../fields";
import { useDispatch, useSelector } from "react-redux";
import List from "../../common/list";
import { funcGet, funcGetById } from "../../../controllers";
import { allLessonAction } from "../../../redux/actions/allLessonAction";
import { lessonActions } from "../../../redux/actions/lessonActions";
import { useHistory } from "react-router-dom";

const Lessons = () => {
	const token = JSON.parse(localStorage.getItem("token"));

	const { alllesson, lesson } = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();

	useEffect(() => {
		getAllCategory();
	}, []);
	const getAllCategory = async () => {
		setLoader(true);
		const res = await funcGet("lecture", token);
		if (res.status === 200) {
			setLoader(false);
			dispatch(allLessonAction(res.data));
		}
	};

	const handleEditor = async (id) => {
		const res = await funcGetById("lecture", id, token);

		dispatch(
			lessonActions({
				...lesson,
				title: res.data.title,
				description: res.data.description,
				category: res.data.category,
				video: {
					v1: res.data.video.v1,
					v2: res.data.video.v2,
				},
				image: res.data.image,
				startingPoint: res.data.startingPoint ?? "registration-day",
				file: res.data.file,
				viewingTime: res.data.viewingTime ?? 0,
				viewerDay: res.data.viewerDay.join(),
				viewerMonth: res.data.viewerMonth.join(),
				viewerYear: res.data.viewerYear.join(),
				course: res.data.course,
				bill: res.data.bill,
				start: res.data.start,
			})
		);
		history.push("./lesson", { edit: true, id: id });
	};
	return (
		<Dushboard>
			<PageInnerMenu data={menuLesson} />
			<div className="all-lessons">
				{loader ? (
					<div className="loader-wrap">
						<Loader type="Puff" color="#00BFFF" height={50} width={50} />
					</div>
				) : (
					<>
						{isStatus ? (
							<div className="course__message">
								<Message message={isMessage} status={isStatus} />
							</div>
						) : null}
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{alllesson.items.length > 0 ? (
										<h3 className="list-category__title">
											Все уроки{" "}
											<span className="list-category__title--span">
												количество {alllesson.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все уроки
										</h3>
									)}
								</div>
								<List data={alllesson.items} hendleEditor={handleEditor} />
							</div>
						</div>
					</>
				)}
			</div>
		</Dushboard>
	);
};
export default Lessons;
