import { ALL_COURSES_PAGE } from "../actionTypes";

export const initAllCoursesPage = {
	items: [],
};

export const allCoursesPage = (state = initAllCoursesPage, action) => {
	switch (action.type) {
		case ALL_COURSES_PAGE:
			return {
				...state,
				items: action.items,
			};
		default:
			return state;
	}
};
