import React, { useEffect, useState, useMemo } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
	funcCreate,
	funcDelete,
	funcEdit,
	funcGet,
} from "../../../controllers";
import { lessonActions } from "../../../redux/actions/lessonActions";
import { initLesson } from "../../../redux/reducers/lesson";
import PageInnerMenu from "../../common/pageInnerMenu";
import {
	Button,
	CheckBox,
	CustomRadio,
	InputText,
	Message,
	Textarea,
	TitlePage,
} from "../../fields";
import ImageBlock from "../../ImageBlock/ImageBlock";
import Dushboard from "../../layout/Dushboard";
import "./style.scss";
import { menuLesson } from "../../data/dataMunu.json";
import { useHistory } from "react-router-dom";
import ListSearch from "../../common/ListSearch";
import FileReaderBlock from "../../fileReaderBlock";
import { allCategoryAction } from "../../../redux/actions/allCategiryAction";
import { allCoursesPageAction } from "../../../redux/actions/allCoursesPageAction";

const startingPoints = [
	{ label: "День регистрации", name: "registration-day" },
	{ label: "День рождения", name: "birthday" },
];

const Lesson = ({ location }) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const { lesson, allcategory, allCoursesPage } = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();
	const defaultVideoSrc = {
		v1: "", // видео с музыкой
		v2: "", // видео без музыки
	};

	const [arrayVideo, setArrayVideo] = useState(lesson.video);
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [selectItemsCat, setSlectItemsCat] = useState(lesson.category);
	const [selectItems, setSlectItems] = useState(lesson.course);
	const [visibleCat, setVisibleCat] = useState(false);
	const [visible, setVisible] = useState(false);
	const [isError, setError] = useState("");
	const [isFiles, setFiles] = useState({
		base64: "",
		fileList: {},
	});
	const [isFilesPdf, setFilesPdf] = useState([]);
	const [isBill, setBill] = useState(lesson.bill);
	const [isStart, setStart] = useState(lesson.start);
	const [startingPoint, setstartingPoint] = useState(lesson.startingPoint);
	const [isSelectedRadio, setRadio] = useState(
		lesson.startingPoint === "registration-day"
			? "День регистрации"
			: "День рождения"
	);
	// eslint-disable-next-line
	useEffect(() => {
		if (allcategory.items.length === 0) {
			dispatch(allCategoryAction({ token }));
		}
		getAll();
	}, []);

	const getAll = async () => {
		const res = await funcGet("courses", token);
		dispatch(allCoursesPageAction(res.data));
	};

	const process = (message, status) => {
		status ? setStatus(true) : setStatus(false);
		setLoader(false);
		setMessage(message);
	};

	// ======================= Запросы на сервер =======
	const saveHandler = async () => {
		const formData = new FormData();
		formData.append("title", lesson.title);
		formData.append("description", lesson.description);
		formData.append("category", JSON.stringify(selectItemsCat));
		formData.append("video", JSON.stringify(arrayVideo));
		formData.append("course", JSON.stringify(selectItems));
		formData.append("image", isFiles.fileList[0]);
		formData.append("startingPoint", startingPoint);
		formData.append("file", isFilesPdf[0]);
		formData.append("viewingTime", lesson.viewingTime);
		formData.append("viewerDay", lesson.viewerDay);
		formData.append("viewerMonth", lesson.viewerMonth);
		formData.append("viewerYear", lesson.viewerYear);
		formData.append("order", lesson.order);
		formData.append("bill", isBill);
		formData.append("start", isStart);

		try {
			setLoader(true);
			const res = await funcCreate(
				formData,
				"lecture",
				token,
				"multipart/form-data"
			);
			if (res.status === 201) {
				process("Успешно сохранено", true);
				resetData();
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			setLoader(false);
		}
	};

	const editHandler = async () => {
		let image = Object.keys(isFiles.fileList).length > 0;
		let file = Object.keys(isFilesPdf).length > 0;

		const formData = new FormData();
		formData.append("title", lesson.title);
		formData.append("description", lesson.description);
		formData.append("category", JSON.stringify(selectItemsCat));
		formData.append("course", JSON.stringify(selectItems));
		formData.append("video", JSON.stringify(arrayVideo));
		formData.append("startingPoint", startingPoint);
		formData.append("image", !image ? lesson.image : isFiles.fileList[0]);
		formData.append("file", !file ? lesson.file : isFilesPdf[0]);
		formData.append("viewingTime", lesson.viewingTime);
		formData.append("viewerDay", lesson.viewerDay);
		formData.append("viewerMonth", lesson.viewerMonth);
		formData.append("viewerYear", lesson.viewerYear);
		formData.append("order", lesson.order);
		formData.append("bill", isBill);
		formData.append("start", isStart);

		try {
			setLoader(true);
			const res = await funcEdit(
				formData,
				"lecture",
				token,
				location.state.id,
				"multipart/form-data"
			);
			if (res.status === 200) {
				process("Успешно сохранено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {}
	};

	const cancelingHandler = async () => {
		history.goBack();
		resetData();
	};

	const deleteHandler = async () => {
		const res = await funcDelete("lecture", token, location.state.id);
		if (res.status === 200) {
			process("Успешно удалено", true);
			resetData();
			setTimeout(() => {
				history.goBack();
			}, 1500);
		}
	};

	// ======================= Обработчики в компаненте =======

	const handleCategory = (item) => {
		!selectItemsCat.includes(item)
			? setSlectItemsCat((selectItemsCat) => [...selectItemsCat, item])
			: setSlectItemsCat((selectItemsCat) =>
					selectItemsCat.filter((el) => el !== item)
			  );
	};

	const handleCoursesPage = (item) => {
		!selectItems.includes(item)
			? setSlectItems((selectItems) => [...selectItems, item])
			: setSlectItems((selectItems) => selectItems.filter((el) => el !== item));
	};

	// ============= стирание всех полей ==========

	const resetData = () => {
		dispatch(lessonActions(initLesson));
		setArrayVideo(defaultVideoSrc);
		setSlectItemsCat([]);
		setFiles({
			base64: "",
			files: {},
		});
		setFilesPdf([]);
	};

	// =========== переключатель точки отсчета для показа ============

	const renderRadioButton = useMemo(() => {
		return startingPoints.map((s, idx) => {
			return (
				<div key={s.label} className="switcher-startPoint__item">
					<CustomRadio
						key={`${s.label}-${idx}`}
						label={s.label}
						selectItem={isSelectedRadio}
						click={(label) => {
							setstartingPoint(s.name);
							setRadio(label);
						}}
					/>
				</div>
			);
		});
	});

	return (
		<Dushboard>
			{location.state !== null && location.state.edit ? (
				<div className="wrapper-edit-button">
					<Button
						battonType="success-button"
						className="course-control"
						click={() => editHandler()}>
						Сохранить
					</Button>
					<Button
						battonType="light-button"
						className="course-control"
						click={() => cancelingHandler()}>
						Отменить
					</Button>
					<Button
						battonType="dark-button"
						className="course-control"
						click={() => deleteHandler()}>
						Удалить
					</Button>
				</div>
			) : (
				<PageInnerMenu data={menuLesson} />
			)}

			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					<div className="lesson">
						{isStatus ? (
							<div className="lesson__message">
								<Message message={isMessage} status={isStatus} />
							</div>
						) : null}
						<TitlePage tag="h2" title="Урок" />
						<div className="row">
							<div className="col col-6 col-l-12 col--m">
								<InputText
									label={"Урок"}
									placeholder="введите название"
									value={lesson.title}
									onChange={(val) => {
										process("", false);
										dispatch(lessonActions({ ...lesson, title: val }));
									}}
								/>
								<ImageBlock
									files={isFiles}
									src={lesson.image}
									text="Загрузите скриншот урока"
									uploadHandler={setFiles}
								/>
								<FileReaderBlock
									loaded={
										isFilesPdf.length > 0 || lesson.file !== "" ? true : false
									}
									text="Загрузите файл"
									uploadHandler={setFilesPdf}
								/>
								<InputText
									label={"ID видео с субтитрами"}
									placeholder="ID"
									value={arrayVideo.v1}
									onChange={(val) => {
										setArrayVideo({ ...arrayVideo, v1: val });
									}}
								/>
								<InputText
									label={"ID видео без субтитров"}
									placeholder="ID"
									value={arrayVideo.v2}
									onChange={(val) => {
										setArrayVideo({ ...arrayVideo, v2: val });
									}}
								/>
								<InputText
									label={"Порядковый номер"}
									placeholder="номер"
									type="Number"
									value={lesson.order}
									onChange={(val) =>
										dispatch(lessonActions({ ...lesson, order: val }))
									}
								/>
								<Textarea
									label={"Описание"}
									value={lesson.description}
									onChange={(val) =>
										dispatch(lessonActions({ ...lesson, description: val }))
									}
								/>
								<div className="select-wrap">
									<ListSearch
										label="Категории"
										isVisible={visibleCat}
										selectedItems={selectItemsCat}
										data={allcategory.items.filter(
											(i) => i.typeCategory === "Уроки"
										)}
										onShow={() => setVisibleCat(!visibleCat)}
										onChange={handleCategory}
									/>
								</div>
								<div className="select-wrap">
									<ListSearch
										label="Курсы"
										isVisible={visibleCat}
										selectedItems={selectItems}
										data={allCoursesPage.items}
										onShow={() => setVisible(!visible)}
										onChange={handleCoursesPage}
									/>
								</div>
								<div className="course-control">
									{location.state !== null && location.state.edit ? null : (
										<Button
											battonType="success-button"
											className="course-control"
											click={() => saveHandler()}>
											Сохранить
										</Button>
									)}
								</div>
							</div>
							<div className="col col-6 col-l-12 col--m">
								<InputText
									label={"Дни показа в -"}
									placeholder="1,2,3"
									value={lesson.viewerDay}
									onChange={(val) =>
										dispatch(lessonActions({ ...lesson, viewerDay: val }))
									}>
									<div className="switcher-startPoint">{renderRadioButton}</div>
								</InputText>
								<InputText
									label={"Месяцы показа"}
									placeholder="1,2,3"
									value={lesson.viewerMonth}
									onChange={(val) =>
										dispatch(lessonActions({ ...lesson, viewerMonth: val }))
									}
								/>
								<InputText
									label={"Год отображения в фильтрах"}
									placeholder="1,2,3"
									value={lesson.viewerYear}
									onChange={(val) =>
										dispatch(lessonActions({ ...lesson, viewerYear: val }))
									}
								/>
								<InputText
									label={"Продолжительность видео"}
									placeholder="Мин"
									value={lesson.viewingTime}
									type="number"
									onChange={(val) =>
										val < 0
											? dispatch(lessonActions({ ...lesson, viewingTime: 0 }))
											: dispatch(lessonActions({ ...lesson, viewingTime: val }))
									}
								/>
								<CheckBox
									label="Стартовое видео"
									isChecked={isStart}
									onClick={() => setStart(!isStart)}
								/>
								<CheckBox
									label="Платное упражнение (по умолчанию)"
									isChecked={isBill}
									onClick={() => setBill(!isBill)}
								/>
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default Lesson;
