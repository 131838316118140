import PropTypes from "prop-types";
import React from "react";

const CustomRadio = ({ label, selectItem, click }) => {
	return (
		<div className="custom-radio" onClick={() => click(label)}>
			<span
				className={
					selectItem !== label
						? "custom-radio__checkbox"
						: "custom-radio__checkbox custom-radio__checkbox--select"
				}></span>
			<label className="custom-radio__label">{label}</label>
		</div>
	);
};

CustomRadio.defaultProps = {
	label: "",
	selectItem: "",
	click: () => null,
};
CustomRadio.propTypes = {
	label: PropTypes.string,
	selectItem: PropTypes.string,
	click: PropTypes.func,
};

export default CustomRadio;
