import React from "react";
import ReactFileReader from "react-file-reader";
import { Button } from "../fields";
import "./style.scss";
import icon from "../../assets/images/file.png";

const FileReaderBlock = ({
	loaded = false,
	uploadHandler,
	text = "Загрузите файл",
}) => {
	return (
		<div className="filereader-block">
			<div className="filereader-block__image--btn">
				<ReactFileReader
					multipleFiles={false}
					fileTypes={[".pdf"]}
					handleFiles={uploadHandler}>
					<Button className="filereader-block" battonType="success-button">
						{text}
					</Button>
				</ReactFileReader>
			</div>
			<div className="filereader-block__icon">
				{loaded && <img src={icon} alt="" />}
			</div>
		</div>
	);
};
export default FileReaderBlock;
