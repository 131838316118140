import { ALL_NORMS } from "../actionTypes";
export const initAllNorms = {
    items : []
}
export const allnorms = (state = initAllNorms, action) => {
    switch (action.type) {
        case ALL_NORMS:
            return {
                ...state,
                items : action.items
            }
    
        default: return state
    }
}