import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { funcGet, funcGetById } from "../../../controllers";
import { allCourseAction } from "../../../redux/actions/allCoursesAction";
import { courseAction } from "../../../redux/actions/courseAction";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { menuCourse } from "../../data/dataMunu.json";
import Loader from "react-loader-spinner";
import { Message } from "../../fields";

import "./style.scss";
import List from "../../common/list";
import { initAllCourses } from "../../../redux/reducers/allcourses";

const AllCourses = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const dispatch = useDispatch();
	const history = useHistory();

	const { allcourses, course } = useSelector((state) => state);
	const [isMessage, setMessage] = useState("");
	const [dataSearch, setDataSearch] = useState();

	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();

	useEffect(() => {
		getAllCourses();
	}, []);

	const getAllCourses = async () => {
		const res = await funcGet("course", token);
		setDataSearch(res.data);
		dispatch(allCourseAction(res.data));
	};

	const handleEditor = async (id) => {
		try {
			const res = await funcGetById("course", id, token);
			if (res.status === 200) {
				dispatch(
					courseAction({
						...course,
						id: res.data._id,
						name: res.data.name,
						video: res.data.video,
						target: res.data.target,
						description: res.data.description,
						category: res.data.category,
						leadTime: {
							start: res.data.leadTime?.start,
							end: res.data.leadTime?.end,
						},
						benefitsLists: res.data.benefitsLists,
						viewerDay: res.data.viewerDay.join([","]),
						viewerMonth: res.data.viewerMonth,
						viewerYear: res.data.viewerYear,
						order: res.data.order,
						image: res.data.image,
						attempts: {
							start: res.data.attempts?.start,
							end: res.data.attempts?.end,
						},
						bill: res.data.bill,
						courseType: res.data.courseType,
						welcoming: res.data.welcoming,
						sortByOrder: res.data.sortByOrder,
					})
				);
				history.push("./course", { edit: true, id: id, path: "/course" });
			}
		} catch (error) {}
	};

	const afterFetch = (status) => {
		if (status === true) {
			setLoader(false);
			setStatus(true);
			setMessage("Успешно сохранено.");
		} else {
			setLoader(false);
			setStatus(false);
			setMessage("Что то пошло не так, попробуйте позже.");
		}
		dispatch(allCourseAction(initAllCourses));
	};

	const getResult = (result) => {
		setDataSearch(result);
	};

	return (
		<Dushboard>
			<PageInnerMenu
				data={menuCourse}
				filterData={allcourses.items}
				getResult={getResult}
			/>
			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					{isStatus ? (
						<div className="lesson__message">
							<Message message={isMessage} status={isStatus} />
						</div>
					) : null}

					<div className="all-courses">
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{allcourses.items.length > 0 ? (
										<h3 className="list-category__title">
											Все упражнения{" "}
											<span className="list-category__title--span">
												количество {allcourses.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все упражнения
										</h3>
									)}
								</div>
								<List data={dataSearch} hendleEditor={handleEditor} />
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default AllCourses;
