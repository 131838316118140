import { CAT } from "../actionTypes";

export const categoryAction = (action) => {
	return {
		type: CAT,
		title: action.title,
		image: action.image,
		imageWhite: action.imageWhite,
		typeCategory: action.typeCategory,
		color: action.color,
	};
};
