import React, { useState } from "react";
import Loader from "react-loader-spinner";
import PageInnerMenu from "../../common/pageInnerMenu";
import { Button, InputText, Message, Textarea, TitlePage } from "../../fields";
import Dushboard from "../../layout/Dushboard";
import { expertsMenu } from "../../data/dataMunu.json";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { expertAction } from "../../../redux/actions/expertsAction";
import ImageBlock from "../../ImageBlock/ImageBlock";
import "./style.scss";
import { funcCreate, funcDelete, funcEdit } from "../../../controllers";
import { initExpert } from "../../../redux/reducers/expert";

const Experts = ({ location }) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const { expert } = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [avatar, setAvatar] = useState({
		base64: "",
		fileList: {},
	});
	const [image, setImage] = useState({
		base64: "",
		fileList: {},
	});

	// =============  =============
	const saveHandler = async () => {
		const formData = new FormData();
		formData.append("title", expert.title);
		formData.append("qualification", expert.qualification);
		formData.append("skills", expert.skills);
		formData.append("avatar", avatar.fileList[0]);
		formData.append("image", image.fileList[0]);

		try {
			setLoader(true);
			const res = await funcCreate(
				formData,
				"create-expert",
				token,
				"multipart/form-data"
			);
			if (res.status === 201) {
				process("Успешно сохранено", true);
				resetData();
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else {
				process("Что то пошло не так", true);
				resetData();
			}
		} catch (error) {
			setLoader(false);
		}
	};

	const editHandler = async () => {
		const formData = new FormData();
		formData.append("title", expert.title);
		formData.append("qualification", expert.qualification);
		formData.append("skills", expert.skills);
		formData.append(
			"avatar",
			avatar.fileList.length > 0 ? avatar.fileList[0] : expert.avatar
		);
		formData.append(
			"image",
			image.fileList.length > 0 ? image.fileList[0] : expert.image
		);

		try {
			setLoader(true);
			const res = await funcEdit(
				formData,
				"expert",
				token,
				location.state.id,
				"multipart/form-data"
			);
			if (res.status === 200) {
				process("Успешно сохранено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			setLoader(false);
		}
	};
	const cancelingHandler = async () => {
		resetData();
		history.goBack();
	};
	const deleteHandler = async () => {
		try {
			setLoader(true);
			const res = await funcDelete("expert", token, location.state.id);
			if (res.status === 200) {
				process("Успешно удалено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			}
		} catch (error) {}
	};
	// =============  =============

	const process = (message, status) => {
		status ? setStatus(true) : setStatus(false);
		setLoader(false);
		setMessage(message);
	};

	const resetData = () => {
		dispatch(expertAction(initExpert));
		setAvatar([]);
		setImage([]);
	};

	return (
		<Dushboard>
			{location.state !== null && location.state.edit ? (
				<div className="wrapper-edit-button">
					<Button
						battonType="success-button"
						className="course-control"
						click={() => editHandler()}>
						Сохранить
					</Button>
					<Button
						battonType="light-button"
						className="course-control"
						click={() => cancelingHandler()}>
						Отменить
					</Button>
					<Button
						battonType="dark-button"
						className="course-control"
						click={() => deleteHandler()}>
						Удалить
					</Button>
				</div>
			) : (
				<PageInnerMenu data={expertsMenu} />
			)}

			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					<div className="content">
						{isStatus ? (
							<Message message={isMessage} status={isStatus} />
						) : null}
						<TitlePage tag="h2" title="Эксперт" />
						<div className="row">
							<div className="col-6 col-l-12 col--m">
								<div className="content__item">
									<InputText
										label={"Имя Фамилия"}
										placeholder="имя фамилия"
										value={expert.title}
										onChange={(val) => {
											process("", false);
											dispatch(expertAction({ ...expert, title: val }));
										}}
									/>
								</div>
								<div className="content__item">
									<InputText
										label={"Квалификация"}
										placeholder="квалификация эксперта"
										value={expert.qualification}
										onChange={(val) => {
											dispatch(expertAction({ ...expert, qualification: val }));
										}}
									/>
								</div>
								<div className="content__item">
									<Textarea
										label={"Опыт"}
										placeholder="опыт эксперта"
										value={expert.skills}
										onChange={(val) => {
											dispatch(expertAction({ ...expert, skills: val }));
										}}
									/>
								</div>
								<div className="content__item">
									<div className="content__images-block">
										<ImageBlock
											files={avatar}
											src={expert.avatar}
											text="Загрузите иконку"
											uploadHandler={setAvatar}
										/>
										<ImageBlock
											files={image}
											src={expert.image}
											text="Загрузите фото"
											uploadHandler={setImage}
										/>
									</div>
								</div>

								<div className="music__button-wrap">
									{location.state !== null && location.state.edit ? null : (
										<Button
											battonType="success-button"
											className="course-control"
											click={() => saveHandler()}>
											Сохранить
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};

export default Experts;
