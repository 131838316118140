import { STATE_MENU } from "../actionTypes";

export const stateMenu = (state = {state : true}, action) => {
	switch (action.type) {
		case STATE_MENU:
			return { ...state,
                state: action.state 
            };
		default:
			return state;
	}
};
