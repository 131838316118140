import axios from "axios";
import { localUrl, url } from "../api/config.http.json";

const funcEdit = async (
	data,
	path,
	token,
	id,
	contentType = "application/json"
) => {
	const res = await axios({
		url: `${url}/${path}/update?id=${id}`,
		method: "patch",
		data: data,
		headers: {
			Authorization: token,
			"Content-Type": contentType,
		},
	});

	return res;
};
export default funcEdit;
