import axios from "axios";
import { url } from "../api/config.http.json";

const funcCreate = async (
	data,
	path,
	token,
	contentType = "application/json"
) => {
	try {
		axios.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error) {
				if (error.response) {
					return Promise.reject(error.response);
				}
				return Promise.reject(error);
			}
		);
		const res = await axios({
			url: `${url}/${path}`,
			method: "post",
			data: data,
			headers: {
				Authorization: token,
				"Content-Type": contentType,
			},
		});

		return res;
	} catch (res) {
		return res;
	}
};
export default funcCreate;
