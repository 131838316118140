import { LESSON } from "../actionTypes";

export const lessonActions = (action) => {
	return {
		type: LESSON,
		title: action.title,
		description: action.description,
		category: action.category,
		video: action.video,
		image: action.image,
		startingPoint: action.startingPoint,
		viewingTime: action.viewingTime,
		viewerDay: action.viewerDay,
		viewerMonth: action.viewerMonth,
		viewerYear: action.viewerYear,
		file: action.file,
		order: action.order,
		course: action.course,
		bill: action.bill,
		start: action.start,
	};
};
