import { combineReducers } from "redux";
import { course } from "./reducers/course";
import { login } from "./reducers/login";
import { user } from "./reducers/user";
import { CourseByDays } from "./reducers/courseByDays";
import { allcourses } from "./reducers/allcourses";
import { category } from "./reducers/category";
import { allcategory } from "./reducers/allcategory";
import { benefits } from "./reducers/benefits";
import { allbenefits } from "./reducers/allbenefits";
import { lesson } from "./reducers/lesson";
import { alllesson } from "./reducers/allLesson";
import { music } from "./reducers/music";
import { allmusic } from "./reducers/allmusic";
import { text } from "./reducers/text";
import { alltexts } from "./reducers/alltext";
import { norms } from "./reducers/norms";
import { allnorms } from "./reducers/allnorms";
import { stateMenu } from "./reducers/stateMenu";
import { childrens } from "./reducers/childrens";
import { lectureByDays } from "./reducers/lectureByDeys";
import { uploadFile } from "./reducers/uploadFile";
import { allfiles } from "./reducers/allFilesUpload";
import { expert } from "./reducers/expert";
import { allexperts } from "./reducers/allExperts";
import { courses } from "./reducers/courses";
import { allCoursesPage } from "./reducers/allCoursesPage";

const reducer = combineReducers({
	course,
	login,
	user,
	CourseByDays,
	allcourses,
	category,
	allcategory,
	benefits,
	allbenefits,
	lesson,
	alllesson,
	music,
	allmusic,
	text,
	alltexts,
	norms,
	allnorms,
	stateMenu,
	childrens,
	lectureByDays,
	uploadFile,
	allfiles,
	expert,
	allexperts,
	courses,
	allCoursesPage,
});
export default reducer;
