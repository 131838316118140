import { LESSON } from "../actionTypes";

export const initLesson = {
	title: "",
	description: "",
	category: [],
	video: {
		v1: "",
		v2: "",
	},
	image: "",
	startingPoint: "registration-day",
	file: "",
	viewingTime: "",
	viewerDay: "",
	viewerMonth: "",
	viewerYear: "",
	order: 0,
	course: [],
	bill: true,
	start: false,
};

export const lesson = (state = initLesson, action) => {
	switch (action.type) {
		case LESSON:
			return {
				...state,
				title: action.title,
				description: action.description,
				category: action.category,
				video: action.video,
				image: action.image,
				startingPoint: action.startingPoint,
				file: action.file,
				viewingTime: action.viewingTime,
				viewerDay: action.viewerDay,
				viewerMonth: action.viewerMonth,
				viewerYear: action.viewerYear,
				order: action.order,
				course: action.course,
				bill: action.bill,
				start: action.start,
			};

		default:
			return state;
	}
};
