import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {categoryAction} from "../../../redux/actions/categoryAction";
import {
    Button,
    CustomRadio,
    InputText,
    Message,
    TitlePage,
} from "../../fields";
import Dushboard from "../../layout/Dushboard";
import "./style.scss";
import {
    funcCreate,
    funcDelete,
    funcEdit
} from "../../../controllers";
import {initCategory} from "../../../redux/reducers/category";
import ColorPicker from "../../colorPicker";
import ImageBlock from "../../ImageBlock";
import PageInnerMenu from "../../common/pageInnerMenu";
import {menuCategory} from "../../data/dataMunu.json";
import {useHistory} from "react-router";
import Loader from "react-loader-spinner";

const radioData = [
    {label: "Уроки", name: "category"},
    {label: "Упражнения", name: "category"},
    {label: "Тексты", name: "category"},
    {label: "Мелодии", name: "category"},
    {label: "Курсы", name: "category"},
];

const Category = ({location}) => {
    const token = JSON.parse(localStorage.getItem("token"));

    const {category} = useSelector((state) => state);
    const [isMessage, setMessage] = useState("");
    const [isStatus, setStatus] = useState();
    const [loader, setLoader] = useState();
    const history = useHistory();
    const dispatch = useDispatch();

    const initFiles = {
        base64: "",
        fileList: [],
    };
    const initFilesWhite = {
        base64: "",
        fileList: [],
    };

    const [files, setFiles] = useState(initFiles);
    const [filesWhite, setFilesWhite] = useState(initFilesWhite);

    const handleRadio = (label) => {
        dispatch(categoryAction({...category, typeCategory: label}));
    };

    const process = (message, status) => {
        status ? setStatus(true) : setStatus(false);
        setLoader(false);
        setMessage(message);
    };

    const renderRadio = useMemo(() => {
        return radioData.map((radio, idx) => {
            return (
                <CustomRadio
                    key={`${radio.label}-${idx}`}
                    label={radio.label}
                    selectItem={category.typeCategory || "Мелодии"}
                    click={(label) => handleRadio(label)}
                />
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [radioData, category]);

    const uploadHandler = (files) => {
        setFiles(files);
        initFilesWhite.base64 === "" &&
        dispatch(categoryAction({...category, imageWhite: ""}));
    };

    const uploadHandlerWhite = (files) => {
        setFilesWhite(files);
        initFiles.base64 === "" &&
        dispatch(categoryAction({...category, image: ""}));
    };

    const saveHandler = async () => {
        const formData = new FormData();
        const createFilesArray = [...files.fileList, ...filesWhite.fileList];

        if (createFilesArray.length === 2) {
            for (let i = 0; i < createFilesArray.length; i++) {
                formData.append("image", createFilesArray[i]);
            }
            formData.append("title", category.title);
            formData.append(
                "typeCategory",
                category.typeCategory === "" ? "Мелодии" : category.typeCategory
            );
            formData.append("color", category.color);

            try {
                setLoader(true);
                const res = await funcCreate(
                    formData,
                    "category",
                    token,
                    "multipart/form-data"
                );

                if (res.status === 201) {
                    process("Успешно сохранено", true);
                    resetData();
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                } else {
                    process("Что то пошло не так", true);
                    resetData();
                }
            } catch (error) {
                setLoader(false);
            }
        } else {
            process("Загрузите все иконки", true);
        }
    };

    const editHandler = async () => {
        const formData = new FormData();

        const createFilesArray = [...files.fileList, ...filesWhite.fileList];

        if (createFilesArray.length > 0) {
            for (let i = 0; i < createFilesArray.length; i++) {
                formData.append("image", createFilesArray[i]);
            }
        } else {
            formData.append("image", category.image);
            formData.append("imageWhite", category.imageWhite);
        }

        formData.append("title", category.title);
        formData.append("typeCategory", category.typeCategory);
        formData.append("color", category.color);

        try {
            setLoader(true);
            const res = await funcEdit(
                formData,
                "category",
                token,
                location.state.id,
                "multipart/form-data"
            );
            if (res.status === 200) {
                process("Успешно сохранено", true);
                resetData();
                setTimeout(() => {
                    history.goBack();
                }, 1500);
            } else {
                process("Что то пошло не так", true);
            }
        } catch (error) {
            setLoader(false);
        }
    };

    const handleColor = (color) => {
        dispatch(categoryAction({...category, color: color}));
    };

    const cancelingHandler = () => {
        dispatch(categoryAction(initCategory));
        history.goBack();
    };

    const deleteHandler = async () => {
        const res = await funcDelete("category", token, location.state.id);
        if (res.status === 200) {
            process("Успешно удалено", true);
            resetData();
            setTimeout(() => {
                history.goBack();
            }, 1500);
        }
    };

    const resetData = () => {
        dispatch(categoryAction(initCategory));
        setFiles(initFiles);
        setFilesWhite(initFilesWhite);
        location.state = null;
    };

    return (
        <Dushboard>
            {location.state !== null && location.state.edit ? (
                <div className="wrapper-edit-button">
                    <Button
                        battonType="success-button"
                        className="course-control"
                        click={() => editHandler()}>
                        Сохранить
                    </Button>
                    <Button
                        battonType="light-button"
                        className="course-control"
                        click={() => cancelingHandler()}>
                        Отменить
                    </Button>
                    <Button
                        battonType="dark-button"
                        className="course-control"
                        click={() => deleteHandler()}>
                        Удалить
                    </Button>
                </div>
            ) : (
                <PageInnerMenu data={menuCategory}/>
            )}

            {loader ? (
                <div className="loader-wrap">
                    <Loader type="Puff" color="#00BFFF" height={50} width={50}/>
                </div>
            ) : (
                <>
                    <div className="category">
                        {isStatus ? (
                            <div className="lesson__message">
                                <Message message={isMessage} status={isStatus}/>
                            </div>
                        ) : null}
                        <TitlePage tag="h1" title="Категории"/>

                        <div className="row row--column-rewerse">
                            <div className="col-12 col-m-6 col--m">
                                <InputText
                                    label={"Урок"}
                                    type="text"
                                    placeholder="введите название"
                                    value={category.title}
                                    onChange={(val) => {
                                        process("", false);
                                        dispatch(categoryAction({...category, title: val}));
                                    }}
                                />
                                <div className="category-types">{renderRadio}</div>
                                <div className="category-colors">
                                    <ColorPicker
                                        defaultColor={category.color}
                                        callback={handleColor}
                                    />
                                </div>
                                <div className="category__wrap-image">
                                    <ImageBlock
                                        files={files}
                                        src={category.image}
                                        text="Загрузите цветную иконку категории"
                                        uploadHandler={uploadHandler}
                                    />
                                    <ImageBlock
                                        files={filesWhite}
                                        src={category.imageWhite}
                                        text="Загрузите белую иконку категории"
                                        uploadHandler={uploadHandlerWhite}
                                    />
                                </div>
                                <div className="course-control">
                                    {location.state !== null && location.state.edit ? null : (
                                        <Button
                                            battonType="success-button"
                                            className="course-control"
                                            click={() => saveHandler()}>
                                            Сохранить
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Dushboard>
    );
};
export default Category;
