import PropType from "prop-types";
import React from "react";

const Button = ({ className, disabled, battonType, children, click }) => {
	return (
		<button
			className={
				className
					? `${className}__button ${battonType}`
					: `${battonType} button`
			}
			disabled={disabled}
			onClick={click}>
			{children}
		</button>
	);
};
Button.defaultProps = {
	className: "",
	children: "",
	battonType: "",
	click: () => null,
};
Button.propType = {
	className: PropType.string,
	children: PropType.string,
	click: PropType.func,
};
export default Button;
