import { AUTH } from "../actionTypes";

export const initLogin = {
	email: "",
	password: "",
};

export const login = (state = initLogin, action) => {
	switch (action.type) {
		case AUTH:
			return {
				...state,
				email: action.email,
				password: action.password,
			};

		default:
			return state;
	}
};
