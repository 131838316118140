import PropType from "prop-types";
import React from "react";

const InputText = ({
	label,
	placeholder,
	name,
	type,
	className,
	value,
	onChange,
	onKeyPress,
	children,
}) => {
	return (
		<div className={className !== "" ? `${className}` : "input-text"}>
			<label
				className={
					className !== "" ? `${className}__label` : "input-text__label"
				}>
				{label}
				{children}
			</label>
			<input
				type={type}
				name={name}
				placeholder={placeholder}
				className={
					className !== "" ? `${className}__input` : "input-text__input"
				}
				value={value}
				onChange={(e) => onChange(e.target.value, e.target.name)}
				onKeyPress={(e) => onKeyPress(e)}
			/>
		</div>
	);
};
InputText.defaultProps = {
	label: "",
	type: "text",
	name: "",
	className: "",
	isValue: "",
	onChange: () => null,
	onKeyPress: () => null,
};
InputText.propType = {
	label: PropType.string,
	type: PropType.string,
	name: PropType.string,
	className: PropType.string,
	isValue: PropType.string,
	onChange: PropType.func,
	onKeyPress: PropType.func,
};
export default InputText;
