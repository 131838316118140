import { MUSIC } from "../actionTypes";

export const initMusic = {
    title: '',
    audio: '',
    text: '',
    category: [],
    viewYear: [],
    link: '',
}

export const music = (state = initMusic, action) => {
    switch (action.type) {
        case MUSIC:
            return {
                ...state,
                title: action.title,
                audio: action.audio,
                text: action.text,
                category: action.category,
                viewYear: action.viewYear,
                link: action.link,
            }
    
        default: return state
    }
}