import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Search from "../search/Search";
import "./style.scss";

const possibleWay = [
	"/all-lesson",
	"/all-music",
	"/all-texts",
	"/all-category",
	"/all-norms",
	"/all-courses",
	"/all-benefits",
	"/all-files",
	"/all-experts",
];

const PageInnerMenu = ({ data, filterData, getResult }) => {
	const location = useLocation();

	const menu = useMemo(() => {
		return data.map((i, idx) => {
			return (
				<li key={idx} className="inner-menu__item">
					<NavLink className="inner-menu__link" to={i.route}>
						{i.title}
					</NavLink>
				</li>
			);
		});
	});

	return (
		<div className="col-12 col-m-6">
			<ul className="inner-menu">
				{menu}
				{possibleWay.includes(location.pathname) && (
					<Search data={filterData} getResult={getResult} />
				)}
			</ul>
		</div>
	);
};
PageInnerMenu.defaultProps = {
	data: [],
};
PageInnerMenu.propTypes = {
	data: PropTypes.array,
};
export default PageInnerMenu;
