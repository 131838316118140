import { ALL_MUSIC } from "../actionTypes";

export const initAlllesson = {
    items : []
}
export const allmusic = (state = initAlllesson, action) => {
    switch (action.type) {
        case ALL_MUSIC:
            return {
                ...state,
                items : action.items
            }
    
        default: return state
    }
}