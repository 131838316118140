import axios from "axios";
import { url } from "../../api/config.http.json";
import { ALLCATEGORY } from "../actionTypes";

export const allCategoryAction = (action) => (dispatch) => {
	axios({
		url: `${url}/category`,
		method: "get",
		headers: {
			Authorization: action.token,
			"Content-Type": "application/json",
		},
	}).then((res) => {
		dispatch({ type: ALLCATEGORY, items: res.data });
	});
};
