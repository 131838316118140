import PropTypes from "prop-types";
import React from "react";

const TitlePage = ({tag = 'h1', title, className}) => {
    const createTitle = (() => {
        switch (tag) {
            case "h1":
                return (
                    <h1
                        className={
                            className !== "" ? `${className}__title` : "heading-page__title"
                        }>
                        {title}
                    </h1>
                );
            case "h2":
                return (
                    <h2
                        className={
                            className !== "" ? `${className}__title` : "heading-page__title"
                        }>
                        {title}
                    </h2>
                );
            case "h3":
                return (
                    <h3
                        className={
                            className !== "" ? `${className}__title` : "heading-page__title"
                        }>
                        {title}
                    </h3>
                );
            case "h4":
                return (
                    <h4
                        className={
                            className !== "" ? `${className}__title` : "heading-page__title"
                        }>
                        {title}
                    </h4>
                );

            default:
                break;
        }
    })();

    return (
        <div className={className !== "" ? `${className}` : "heading-page"}>
            {createTitle}
        </div>
    );
};
TitlePage.defaultProps = {
    title: "",
    className: "",
};
TitlePage.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
};
export default TitlePage;
