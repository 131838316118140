import { COURSE } from "../actionTypes";

export const initCourse = {
	id: 0,
	name: "",
	video: {
		v1: "",
		v2: "",
		v3: "",
		v4: "",
	},
	target: "",
	description: "",
	category: [],
	leadTime: {
		start: "",
		end: "",
	},
	attempts: {
		start: "",
		end: "",
	},
	benefitsLists: [],
	viewerDay: "",
	viewerMonth: "",
	viewerYear: "",
	order: 0,
	image: "",
	bill: true,
	courseType: "Игры и упражнения",
	welcoming: false,
	sortByOrder: [],
};

export const course = (state = initCourse, action) => {
	switch (action.type) {
		case COURSE:
			return {
				...state,
				id: action.id,
				name: action.name,
				video: action.video,
				target: action.target,
				description: action.description,
				category: action.category,
				leadTime: {
					start: action?.leadTime?.start,
					end: action?.leadTime?.end,
				},
				benefitsLists: action.benefitsLists,
				viewerDay: action.viewerDay,
				viewerMonth: action.viewerMonth,
				viewerYear: action.viewerYear,
				order: action.order,
				image: action.image,
				attempts: {
					start: action?.attempts?.start,
					end: action?.attempts?.end,
				},
				bill: action.bill,
				courseType: action.courseType,
				welcoming: action.welcoming,
				sortByOrder: action.sortByOrder,
			};

		default:
			return state;
	}
};
