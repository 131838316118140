import { COURSE } from "../actionTypes";

export const courseAction = (action) => {
	return {
		type: COURSE,
		id: action._id,
		name: action.name,
		video: action.video,
		target: action.target,
		description: action.description,
		category: action.category,
		leadTime: {
			start: action.leadTime?.start,
			end: action.leadTime?.end,
		},
		benefitsLists: action.benefitsLists,
		viewerDay: action.viewerDay,
		viewerMonth: action.viewerMonth,
		viewerYear: action.viewerYear,
		order: action.order,
		image: action.image,
		attempts: {
			start: action.attempts?.start,
			end: action.attempts?.end,
		},
		bill: action.bill,
		courseType: action.courseType,
		welcoming: action.welcoming,
		sortByOrder: action.sortByOrder,
	};
};
