import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PageInnerMenu from "../../common/pageInnerMenu";
import {
	Button,
	CheckBox,
	InputText,
	Message,
	Textarea,
	TitlePage,
} from "../../fields";
import Dushboard from "../../layout/Dushboard";
import { coursesPageMenu } from "../../data/dataMunu.json";
import { coursesAction } from "../../../redux/actions/coursesAction";
import { initCoursesPage } from "../../../redux/reducers/courses";
import ImageBlock from "../../ImageBlock/ImageBlock";
import { allExpertsAction } from "../../../redux/actions/allExpertsAction";
import ListSearch from "../../common/ListSearch";
import {
	funcCreate,
	funcDelete,
	funcEdit,
	funcGet,
} from "../../../controllers";
import { allCategoryAction } from "../../../redux/actions/allCategiryAction";

const CoursesPage = ({ location }) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const { courses, allexperts, allcategory } = useSelector((state) => state);

	const dispatch = useDispatch();
	const history = useHistory();
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [visible, setVisible] = useState(false);
	const [selectItems, setSlectItems] = useState(
		courses.expertsList.map((i) => i.title)
	);
	const [selectItemsCat, setSlectItemsCat] = useState(courses.category);
	const [createSelectExperts, setCreateSelectExperts] = useState([]);
	const [cover, setCover] = useState({
		base64: "",
		fileList: {},
	});
	useEffect(() => {
		getAll();
		dispatch(allCategoryAction({ token }));
	}, []);

	const getAll = async () => {
		const res = await funcGet("experts", token);
		dispatch(allExpertsAction(res.data));
	};

	const handleSelect = (item) => {
		const expertCreateData = allexperts.items
			.filter((i) => i.title === item)
			.map((expert, idx) => {
				return {
					title: expert.title,
					image: expert.image,
					avatar: expert.avatar,
					skills: expert.skills,
				};
			})
			.pop();

		!selectItems.includes(item)
			? setSlectItems((selectItems) => [...selectItems, item])
			: setSlectItems((selectItems) => selectItems.filter((el) => el !== item));

		!createSelectExperts.some((el) => el.title === item)
			? setCreateSelectExperts((createSelectExperts) => [
					...createSelectExperts,
					expertCreateData,
			  ])
			: setCreateSelectExperts((createSelectExperts) =>
					createSelectExperts.filter(
						(el) => el.title !== expertCreateData.title
					)
			  );
	};

	const handleSelectCat = (item) => {
		!selectItemsCat.includes(item)
			? setSlectItemsCat((selectItemsCat) => [...selectItemsCat, item])
			: setSlectItemsCat((selectItemsCat) =>
					selectItemsCat.filter((el) => el !== item)
			  );
	};

	// ===========  ================
	const saveHandler = async () => {
		const formData = new FormData();
		formData.append("title", courses.title);
		formData.append("description", courses.description);
		formData.append("image", cover.fileList[0]);
		formData.append("viewerYear", courses.viewerYear);
		formData.append("price", courses.price);
		formData.append("bill", courses.bill);
		formData.append("expertsList", JSON.stringify(createSelectExperts));
		formData.append("category", JSON.stringify(selectItemsCat));

		try {
			setLoader(true);
			const res = await funcCreate(
				formData,
				"create-courses",
				token,
				"multipart/form-data"
			);
			if (res.status === 201) {
				process("Успешно сохранено", true);
				resetData();
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else {
				process("Что то пошло не так", true);
				resetData();
			}
		} catch (error) {
			setLoader(false);
		}
	};

	const editHandler = async () => {
		const formData = new FormData();
		formData.append("title", courses.title);
		formData.append("description", courses.description);
		formData.append(
			"image",
			cover.fileList.length > 0 ? cover.fileList[0] : courses.image
		);
		formData.append("viewerYear", courses.viewerYear);
		formData.append("price", courses.price);
		formData.append("bill", courses.bill);
		formData.append("expertsList", JSON.stringify(createSelectExperts));
		formData.append("category", JSON.stringify(selectItemsCat));

		try {
			setLoader(true);
			const res = await funcEdit(
				formData,
				"courses",
				token,
				location.state.id,
				"multipart/form-data"
			);
			if (res.status === 200) {
				process("Успешно сохранено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			setLoader(false);
		}
	};

	const cancelingHandler = async () => {
		resetData();
		history.goBack();
	};

	const deleteHandler = async () => {
		try {
			setLoader(true);
			const res = await funcDelete("courses", token, location.state.id);
			if (res.status === 200) {
				process("Успешно удалено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			}
		} catch (error) {}
	};

	// ===========  ================

	const process = (message, status) => {
		status ? setStatus(true) : setStatus(false);
		setLoader(false);
		setMessage(message);
	};

	const resetData = () => {
		dispatch(coursesAction(initCoursesPage));
		setCover([]);
		setSlectItems([]);
		setSlectItemsCat([]);
	};

	return (
		<Dushboard>
			{location.state !== null && location.state.edit ? (
				<div className="wrapper-edit-button">
					<Button
						battonType="success-button"
						className="course-control"
						click={() => editHandler()}>
						Сохранить
					</Button>
					<Button
						battonType="light-button"
						className="course-control"
						click={() => cancelingHandler()}>
						Отменить
					</Button>
					<Button
						battonType="dark-button"
						className="course-control"
						click={() => deleteHandler()}>
						Удалить
					</Button>
				</div>
			) : (
				<PageInnerMenu data={coursesPageMenu} />
			)}

			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					<div className="content">
						{isStatus ? (
							<Message message={isMessage} status={isStatus} />
						) : null}
						<TitlePage tag="h2" title="Курс" />
						<div className="row">
							<div className="col-6 col-l-12 col--m">
								<div className="content__item">
									<InputText
										label={"Название"}
										placeholder="Название курса"
										value={courses.title}
										onChange={(val) => {
											process("", false);
											dispatch(coursesAction({ ...courses, title: val }));
										}}
									/>
								</div>
								<div className="content__item">
									<Textarea
										label={"Описание"}
										value={courses.description}
										onChange={(val) => {
											dispatch(coursesAction({ ...courses, description: val }));
										}}
									/>
								</div>
								<div className="content__item">
									<ImageBlock
										files={cover}
										src={courses.image}
										text="Загрузите обложку"
										uploadHandler={setCover}
									/>
								</div>
								<div className="content__item">
									<InputText
										label={"Цена"}
										placeholder="цена курса"
										value={courses.price}
										type="Number"
										onChange={(val) => {
											dispatch(coursesAction({ ...courses, price: val }));
										}}
									/>
								</div>
								<div className="content__item">
									<InputText
										label={"Год показа"}
										placeholder="1,2,3"
										value={courses.viewerYear}
										onChange={(val) => {
											dispatch(coursesAction({ ...courses, viewerYear: val }));
										}}
									/>
								</div>
								<div className="content__item">
									<ListSearch
										label="Эксперты"
										isVisible={visible}
										selectedItems={selectItems}
										data={allexperts.items}
										onShow={() => setVisible(!visible)}
										onChange={handleSelect}
									/>
								</div>
								<div className="content__item">
									<ListSearch
										label="Категории"
										isVisible={visible}
										selectedItems={selectItemsCat}
										data={allcategory.items.filter(
											(i) => i.typeCategory === "Курсы"
										)}
										onShow={() => setVisible(!visible)}
										onChange={handleSelectCat}
									/>
								</div>
								<div className="content__item">
									<CheckBox
										label="Платный"
										isChecked={courses.bill ? true : false}
										onClick={() =>
											dispatch(
												coursesAction({ ...courses, bill: !courses.bill })
											)
										}
									/>
								</div>
								<div className="music__button-wrap">
									{location.state !== null && location.state.edit ? null : (
										<Button
											battonType="success-button"
											className="course-control"
											click={() => saveHandler()}>
											Сохранить
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};

export default CoursesPage;
