import PropTypes from "prop-types";
import React from "react";
import Header from "../../common/header/Header";
import LeftMenu from "../../common/leftMenu/LeftMenu";
import DushboardTabs from "../DusboardTabs/DushboardTabs";

import "./style.scss";

const Dushboard = ({ leftMenu, children }) => {
	return (
		<div className="panel">
			<div className="panel__inner">
				<div className="panel__left">
					<LeftMenu />
				</div>
				<div className="panel__right">
					<Header />
					<DushboardTabs>{children}</DushboardTabs>
				</div>
			</div>
		</div>
	);
};
Dushboard.propTypes = {
	leftMenu: PropTypes.node,
	children: PropTypes.node,
};
export default Dushboard;
