import { UPLOAD_FILE } from "../actionTypes";

export const uploadFileAction = (action) => {
	return {
		type: UPLOAD_FILE,
		id: action.id,
		title: action.title,
		category: action.category,
		file: action.file,
	};
};
