import { EXPERTS } from "../actionTypes";

export const expertAction = (action) => {
	return {
		type: EXPERTS,
		title: action.title,
		qualification: action.qualification,
		skills: action.skills,
		avatar: action.avatar,
		image: action.image,
	};
};
