import { ALL_LESSON } from "../actionTypes";

export const initAlllesson = {
    items : []
}

export const alllesson = (state = initAlllesson, action) => {
    switch (action.type) {
        case ALL_LESSON:
            return {
                ...state,
                items : action.items
            }
    
        default: return state
    }
}