import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { menuText } from "../../data/dataMunu.json";
import "./style.scss";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { funcGet, funcGetById } from "../../../controllers";
import { allTextAction } from "../../../redux/actions/allTextAction";
import { textAction } from "../../../redux/actions/textAction";
import { Message } from "../../fields";
import List from "../../common/list";

const AllTexts = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const dispatch = useDispatch();
	const history = useHistory();
	const { alltexts, text } = useSelector((state) => state);
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [dataSearch, setDataSearch] = useState();

	useEffect(() => {
		getAllText();
	}, []);

	const getAllText = async () => {
		const res = await funcGet("text", token);
		if (res.status === 200) {
			setLoader(false);
			setDataSearch(res.data);
			dispatch(allTextAction(res.data));
		}
	};

	const handleEditor = async (id) => {
		const res = await funcGetById("text", id, token);
		dispatch(
			textAction({
				...text,
				title: res.data.title,
				text: res.data.text,
				video: res.data.video,
				category: res.data.category,
				viewYear: res.data.viewYear.join(),
				link: res.data.link,
			})
		);
		history.push("./text", { edit: true, id: id });
	};

	const getResult = (result) => {
		setDataSearch(result);
	};

	return (
		<Dushboard>
			<PageInnerMenu
				data={menuText}
				filterData={alltexts.items}
				getResult={getResult}
			/>
			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					{isStatus ? (
						<div className="lesson__message">
							<Message message={isMessage} status={isStatus} />
						</div>
					) : null}
					<div className="all-texts">
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{alltexts.items.length > 0 ? (
										<h3 className="list-category__title">
											Все тексты{" "}
											<span className="list-category__title--span">
												количество {alltexts.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все тексты
										</h3>
									)}
								</div>
								<List data={dataSearch} hendleEditor={handleEditor} />
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default AllTexts;
