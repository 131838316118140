import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHttp } from "../../../hooks/http.hook";
import { url, localUrl } from "../../../api/config.http.json";
import { loginAction } from "../../../redux/actions/loginAction";
import { Button, InputText } from "../../fields";
import logo from "../../../assets/images/logo.png";
import { userAction } from "../../../redux/actions/userAction";
import "./style.scss";
import { useHistory } from "react-router-dom";
import LoaderCompanent from "../../common/loader/index";
import { initLogin } from "../../../redux/reducers/login";

const Auth = () => {
	const history = useHistory();
	const { login } = useSelector((state) => state);
	const [isError, setError] = useState("");
	const dispatch = useDispatch();
	const { response, loader } = useHttp();

	const fetchHandler = async () => {
		const res = await response(`${url}/auth/login`, "POST", login);

		if (res) {
			if (Object.keys(res).includes("token")) {
				dispatch(
					userAction({
						...userAction,
						token: res.token,
						id: res.id,
						login: true,
					})
				);
				localStorage.setItem(
					"user",
					JSON.stringify({ email: login.email, password: login.password })
				);
				await setToken(res.token);
				dispatch(loginAction(initLogin));
			} else {
				setError(res.message);
			}
		} else {
			setError("Что то пошло не так, попробуйте позже.");
		}
	};

	const setToken = async (token) => {
		localStorage.setItem("token", token);
		const userToken = localStorage.getItem("token");
		if (userToken) {
			history.push("/");
		}
	};

	return (
		<div className="login">
			<div className="login__inner">
				{loader ? (
					<LoaderCompanent />
				) : (
					<>
						<div className="login__wrap-logo">
							<img src={logo} alt="img" />
						</div>
						<span className="login__error">{isError}</span>
						<InputText
							label="login"
							value={login.login}
							className="login-input"
							onChange={(val) =>
								dispatch(loginAction({ ...login, email: val }))
							}
						/>
						<InputText
							label="password"
							value={login.password}
							type="password"
							className="login-input"
							onChange={(val) =>
								dispatch(loginAction({ ...login, password: val }))
							}
						/>
						<Button
							className="login__button success"
							click={() => fetchHandler()}>
							Войти
						</Button>
					</>
				)}
			</div>
		</div>
	);
};
export default Auth;
