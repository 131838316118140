import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { menuCourse } from "../../data/dataMunu.json";
import { Button, CheckBox, InputText, Message } from "../../fields";
import { useHttp } from "../../../hooks/http.hook";
import { url } from "../../../api/config.http.json";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { coursesByDayActions } from "../../../redux/actions/coursesByDayActions";

import editor from "../../../assets/images/editor.png";
import { useHistory } from "react-router-dom";
import { courseAction } from "../../../redux/actions/courseAction";
import Loader from "react-loader-spinner";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { lectureByDayActions } from "../../../redux/actions/lectureByDayActions";
import { lessonActions } from "../../../redux/actions/lessonActions";
import { saveOrder } from "../../../controllers";
import ArrowFetch from "./Arrow";

const mockArr = [
	{
		benefitsLists: (2)[("Звучащие коробочки", "Погремушка")],
		bill: true,
		category: ["Мышление"],
		courseType: "Игры и упражнения",
		description: "Потешка для развития малыша",
		image:
			"http://bo.babycourse.ru/images/13032021-103020_332-Упражнение_Хрюша.jpg",
		leadTime: { start: "1", end: "2" },
		name: "Первый",
		order: 0,
		sortByOrder: [
			[1, 3],
			[2, 1],
			[3, 0],
			[4, 4],
		],
		target: "Развлечь ребенка и поддержать его развитие",
		video: {
			v1: "523194076",
			v2: "523194076",
			v3: "523194076",
			v4: "523194076",
		},
		viewerDay: (7)[("1", "2", "3", "4", "5", "6", "7")],
		viewerMonth: ["4"],
		viewerYear: ["1"],
		welcoming: true,
		__v: 0,
		_id: "604cda8c74600e6d161278c2",
	},
	{
		benefitsLists: (2)[("Звучащие коробочки", "Погремушка")],
		bill: true,
		category: ["Мышление"],
		courseType: "Игры и упражнения",
		description: "Потешка для развития малыша",
		image:
			"http://bo.babycourse.ru/images/13032021-103020_332-Упражнение_Хрюша.jpg",
		leadTime: { start: "1", end: "2" },
		name: "Второй",
		order: 0,
		sortByOrder: [
			[1, 1],
			[2, 1],
			[3, 0],
			[4, 4],
		],
		target: "Развлечь ребенка и поддержать его развитие",
		video: {
			v1: "523194076",
			v2: "523194076",
			v3: "523194076",
			v4: "523194076",
		},
		viewerDay: (7)[("1", "2", "3", "4", "5", "6", "7")],
		viewerMonth: ["4"],
		viewerYear: ["1"],
		welcoming: true,
		__v: 0,
		_id: "604cda8c74600e6d161278c2",
	},
	{
		benefitsLists: (2)[("Звучащие коробочки", "Погремушка")],
		bill: true,
		category: ["Мышление"],
		courseType: "Игры и упражнения",
		description: "Потешка для развития малыша",
		image:
			"http://bo.babycourse.ru/images/13032021-103020_332-Упражнение_Хрюша.jpg",
		leadTime: { start: "1", end: "2" },
		name: "Третий",
		order: 0,
		sortByOrder: [
			[1, 2],
			[2, 1],
			[3, 0],
			[4, 4],
		],
		target: "Развлечь ребенка и поддержать его развитие",
		video: {
			v1: "523194076",
			v2: "523194076",
			v3: "523194076",
			v4: "523194076",
		},
		viewerDay: (7)[("1", "2", "3", "4", "5", "6", "7")],
		viewerMonth: ["4"],
		viewerYear: ["1"],
		welcoming: true,
		__v: 0,
		_id: "604cda8c74600e6d161278c2",
	},
];

let grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	display: "flex",
	alignItems: "center",
	userSelect: "none",
	padding: "2px 10px 2px 2px",
	margin: `0 0 ${grid}px 0`,
	borderRadius: "5px",
	cursor: "move",
	background: isDragging ? "#cfdaec" : "white",
	...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? "#84a5ff" : "lightgrey",
	padding: grid,
	paddingBottom: "4px",
	width: "100%",
	borderRadius: "5px",
});

const SortingActivities = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const history = useHistory();
	const [sortDay, setSortDay] = useState("1");
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	// const [isSort, setSort] = useState(false);
	let [isBillableCourse, setBillableCourse] = useState([]);
	let [isBillableLecture, setBillableLecture] = useState([]);
	const { CourseByDays, lectureByDays } = useSelector((state) => state);
	const [cardData, setCardData] = useState([]);
	const [sondsData, setSondsData] = useState([]);
	const [lectureData, setLectureData] = useState(lectureByDays.items);
	const dispatch = useDispatch();
	const { response, loader } = useHttp();

	useEffect(() => {
		isMessage !== "" && setTimeout(() => setMessage(""), 2000);
	}, [isMessage]);

	// =========== =========== =========
	// =========== sort course =========
	// =========== =========== =========

	const compareOrderCourse = (a, b) => {
		let dayA = a.sortByOrder
			.filter((arrA) => arrA.day === sortDay)
			.map((i) => i.order);
		let dayB = b.sortByOrder
			.filter((arrB) => arrB.day === sortDay)
			.map((i) => i.order);
		return dayA - dayB;
	};

	const compareOrder = (a, b) => {
		return a.order - b.order;
	};

	const addAttributes = async (res, item) => {
		if (item === "course") {
			const sortArr = await res; //.sort(compareOrderCourse);

			const billable = await res
				.filter((i) => i.bill === true)
				.map((i) => i._id);

			// ========= filter course by type-course ==========

			const songs = await sortArr
				.filter((i) => i.courseType === "Песенки и потешки")
				.sort(compareOrderCourse);

			const lesson = await sortArr
				.filter((i) => i.courseType === "Игры и упражнения")
				.sort(compareOrderCourse);
			// ========= filter course by type-course ==========

			setCardData(lesson);
			setSondsData(songs);
			setBillableCourse(billable);
		} else {
			const sortArr = await res.sort(compareOrder);
			const billable = await res
				.filter((i) => i.bill === true)
				.map((i) => i._id);
			setLectureData(sortArr);
			setBillableLecture(billable);
		}
	};
	const saveDayHandle = async () => {
		setSortDay(sortDay);
		await saveCourse();
		await saveLecture();
	};

	// const fetchPrev = async (day) => {
	// 	if (day - 1 > 0) {
	// 		// setSortDay(`${+day - 1}`);
	// 		courseByDayHandle(`${+day - 1}`);
	// 	}
	// };
	// const fetchNext = async (day) => {
	// 	// setSortDay(`${+day + 1}`);
	// 	courseByDayHandle(`${+day + 1}`);
	// };

	const courseByDayHandle = async (day) => {
		setSortDay(`${+day}`);
		try {
			const res = await response(
				`${url}/course-by-day`,
				"POST",
				{ day: day },
				{
					Authorization: token,
					"Content-Type": "application/json",
				}
			);

			dispatch(coursesByDayActions(res));
			addAttributes(res, "course");
		} catch (error) {}
		try {
			const res = await response(
				`${url}/lecture-by-day`,
				"POST",
				{ day: day },
				{
					Authorization: token,
					"Content-Type": "application/json",
				}
			);

			dispatch(lectureByDayActions(res));
			addAttributes(res, "lecture");
		} catch (error) {}
	};

	const saveCourse = async () => {
		const dataCourse = cardData
			.map((i, idx) => {
				let sorted = i.sortByOrder.map((order) => {
					if (order.day === sortDay) {
						return {
							...order,
							order: idx,
						};
					}
					return order;
				});

				return {
					...i,
					order: idx,
					sortByOrder: sorted,
				};
			})
			.map((i) => {
				if (isBillableCourse.includes(i._id)) {
					return { ...i, bill: true };
				} else {
					return { ...i, bill: false };
				}
			});

		const dataSonds = sondsData
			.map((i, idx) => {
				let sorted = i.sortByOrder.map((order) => {
					if (order.day === sortDay) {
						return {
							...order,
							order: idx,
						};
					}
					return order;
				});
				return {
					...i,
					order: idx,
					sortByOrder: sorted,
				};
			})
			.map((i) => {
				if (isBillableCourse.includes(i._id)) {
					return { ...i, bill: true };
				} else {
					return { ...i, bill: false };
				}
			});

		const res = await saveOrder([...dataCourse, ...dataSonds], "course", token);
		if (res.status === 200) {
			await courseByDayHandle(sortDay);
			setMessage(res.data.message);
			setStatus(true);
		} else {
			await courseByDayHandle(sortDay);
			setMessage(res.data.message);
			setStatus(false);
		}
	};

	const saveLecture = async () => {
		const data = await lectureData
			.map((i, idx) => {
				return {
					...i,
					order: idx,
				};
			})
			.map((i) => {
				if (isBillableLecture.includes(i._id)) {
					return { ...i, bill: true };
				} else {
					return { ...i, bill: false };
				}
			});

		const res = await saveOrder(data, "lecture", token);
		if (res.status === 200) {
			setMessage(res.data.message);
			setStatus(true);
		} else {
			setMessage(res.data.message);
			setStatus(false);
		}
	};

	const cancellationSortDay = () => {
		setSortDay("");
		setCardData([]);
		setSondsData([]);
		setLectureData([]);
		setBillableCourse([]);
		setBillableLecture([]);
	};

	const onKeyPressEnter = (e) => {
		if (e.key === "Enter") {
			courseByDayHandle(sortDay);
		}
	};

	// =========== =========== =========
	// =========== sort course =========
	// =========== =========== =========

	const editCourseHandle = (course) => {
		dispatch(courseAction(course));
		history.push("./course", { edit: true, id: course._id });
	};

	const editLectureHandle = (lesson) => {
		dispatch(lessonActions(lesson));
		history.push("./lesson", { edit: true, id: lesson._id });
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		const items = reorder(
			cardData,
			result.source.index,
			result.destination.index
		);
		setCardData(items);
	};

	const onDragEndSouns = (result) => {
		if (!result.destination) {
			return;
		}

		const items = reorder(
			sondsData,
			result.source.index,
			result.destination.index
		);
		setSondsData(items);
	};

	const onDragEndLecture = (result) => {
		if (!result.destination) {
			return;
		}

		const items = reorder(
			lectureData,
			result.source.index,
			result.destination.index
		);
		setLectureData(items);
	};

	// ================ sort by time ==========

	let totalLeadTimeArr = [];
	let totalAttemptsArr = [];
	let totalViewingTime = lectureData.map((i) => i.viewingTime);
	if (cardData.length > 0) {
		for (const iterator of cardData) {
			iterator?.leadTime
				? (totalLeadTimeArr = [...totalLeadTimeArr, +iterator?.leadTime.start])
				: (totalAttemptsArr = [...totalAttemptsArr, +iterator?.attempts.start]);
		}
	}

	const totalLeadTime = [
		...totalLeadTimeArr,
		...totalAttemptsArr,
		...totalViewingTime,
	].reduce((ass, i) => ass + i, 0);

	// ============== handle billable ===========

	const handleSetBillableCourse = async (id) => {
		!isBillableCourse.includes(id)
			? setBillableCourse((isBillableCourse) => [...isBillableCourse, id])
			: setBillableCourse((isBillableCourse) =>
					isBillableCourse.filter((el) => el !== id)
			  );
	};

	const handleSetBillableLecture = async (id) => {
		!isBillableLecture.includes(id)
			? setBillableLecture((isBillableLecture) => [...isBillableLecture, id])
			: setBillableLecture((isBillableLecture) =>
					isBillableLecture.filter((el) => el !== id)
			  );
	};

	return (
		<Dushboard>
			<div className="save-sort-btn-wrap">
				<Button
					battonType="success-button"
					className="course-sort"
					click={() => saveDayHandle()}>
					Сохранить
				</Button>
				<Button
					battonType="light-button"
					className="course-sort"
					click={() => cancellationSortDay()}>
					Отмена
				</Button>
			</div>

			<div className="courses">
				<div className="course-sort">
					<p className="course-sort__title">Выберите день</p>
					<div className="course-sort__body">
						<InputText
							type="Number"
							className="course-sort-input"
							value={sortDay}
							onChange={(val) => (val < 0 ? setSortDay("1") : setSortDay(val))}
							onKeyPress={(e) => onKeyPressEnter(e)}
						/>
						<Button
							battonType="light-button"
							className="course-sort"
							click={() => courseByDayHandle(sortDay)}>
							Сортировать
						</Button>
						<div className="course-sort__data-time">
							<span className="course-sort__data-text">
								Общее время выполнения
							</span>
							<span className="course-sort__data-text">
								{totalLeadTime} мин
							</span>
						</div>
					</div>
					{/* <div className="course-sort__arrow">
						<ArrowFetch
							prev={() => fetchPrev(sortDay)}
							next={() => fetchNext(sortDay)}
						/>
					</div> */}
				</div>
				<div className="courses__header">
					<Message message={isMessage} status={isStatus} />
				</div>
				{loader ? (
					<div className="loader-wrap">
						<Loader type="Puff" color="#00BFFF" height={50} width={50} />
					</div>
				) : (
					<div className="wrap-dragdable">
						<div className="wrap-dragdable__item">
							<div className="wrap-dragdable__header">
								{lectureData.length > 0 && (
									<p className="wrap-dragdable__title">Уроки</p>
								)}
							</div>
							<DragDropContext onDragEnd={onDragEndLecture}>
								{lectureData.length > 0 && (
									<Droppable droppableId="droppable">
										{(provided, snapshot) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={getListStyle(snapshot.isDraggingOver)}>
												{lectureData?.map((item, index) => {
													return (
														<Draggable
															key={item._id}
															draggableId={item._id}
															index={index}>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}>
																	<img
																		className="item__image"
																		src={item.image}
																		alt=""
																	/>
																	<span className="item__title">
																		{item.title}
																	</span>
																	<span className="item__order">
																		{item.order}
																	</span>
																	<div className="item__time">
																		<span className="item__text-m item__text-m--mx">
																			Время
																		</span>
																		<span className="item__text-m">
																			{item.viewingTime ?? 0}
																		</span>
																	</div>
																	<span
																		className="item__edit"
																		onClick={() => editLectureHandle(item)}>
																		<img
																			className="item__edit-img"
																			src={editor}
																			alt=""
																		/>
																	</span>
																	<div className="item__bill">
																		<CheckBox
																			label="Платное"
																			isChecked={
																				isBillableLecture.includes(item._id)
																					? true
																					: false
																			}
																			onClick={() =>
																				handleSetBillableLecture(item._id)
																			}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								)}
							</DragDropContext>
						</div>
						<div className="wrap-dragdable__item">
							<div className="wrap-dragdable__header">
								{cardData.length > 0 && (
									<p className="wrap-dragdable__title">Упражнения</p>
								)}
							</div>

							<DragDropContext onDragEnd={onDragEnd}>
								{cardData.length > 0 && (
									<Droppable droppableId="droppable">
										{(provided, snapshot) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={getListStyle(snapshot.isDraggingOver)}>
												{cardData?.map((item, index) => {
													let order = item.sortByOrder
														.filter((order) => order.day === sortDay)
														.map((o) => o)
														.pop();
													return (
														<Draggable
															key={item._id}
															draggableId={item._id}
															index={index}>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}>
																	<img
																		className="item__image"
																		src={item.image}
																		alt=""
																	/>
																	<span className="item__title">
																		{item.name}
																	</span>
																	{item.category.map((i) => (
																		<span
																			key={i}
																			className="item__text item__text-m item__text-m--mx item__text--category">
																			{i}
																		</span>
																	))}

																	<span className="item__text item__text-m item__text-m--mx">
																		{item.courseType}
																	</span>
																	<span className="item__order">
																		{order?.order}
																	</span>
																	{item?.leadTime ? (
																		<div className="item__time">
																			<span className="item__text-m item__text-m--mx">
																				Время
																			</span>
																			<span className="item__text-m">
																				{item.leadTime.start} -{" "}
																				{item.leadTime.end}{" "}
																			</span>
																		</div>
																	) : (
																		<div className="item__time">
																			<span className="item__text-m item__text-m--mx">
																				Попыток
																			</span>
																			<span className="item__text-m">
																				{item.attempts.start} -{" "}
																				{item.attempts.end}
																			</span>
																		</div>
																	)}
																	<span
																		className="item__edit"
																		onClick={() => editCourseHandle(item)}>
																		<img
																			className="item__edit-img"
																			src={editor}
																			alt=""
																		/>
																	</span>
																	<div className="item__bill">
																		<CheckBox
																			label="Платное"
																			isChecked={
																				isBillableCourse.includes(item._id)
																					? true
																					: false
																			}
																			onClick={() =>
																				handleSetBillableCourse(item._id)
																			}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								)}
							</DragDropContext>
						</div>
						<div className="wrap-dragdable__item">
							<div className="wrap-dragdable__header">
								{cardData.length > 0 && (
									<p className="wrap-dragdable__title">Песенки и потешки</p>
								)}
							</div>

							<DragDropContext onDragEnd={onDragEndSouns}>
								{sondsData.length > 0 && (
									<Droppable droppableId="droppable">
										{(provided, snapshot) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												style={getListStyle(snapshot.isDraggingOver)}>
												{sondsData?.map((item, index) => {
													return (
														<Draggable
															key={item._id}
															draggableId={item._id}
															index={index}>
															{(provided, snapshot) => (
																<div
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided.draggableProps.style
																	)}>
																	<img
																		className="item__image"
																		src={item.image}
																		alt=""
																	/>
																	<span className="item__title">
																		{item.name}
																	</span>
																	<span className="item__text item__text-m item__text-m--mx">
																		{item.courseType}
																	</span>
																	<span className="item__order">
																		{item.order}
																	</span>
																	{item?.leadTime ? (
																		<div className="item__time">
																			<span className="item__text-m item__text-m--mx">
																				Время
																			</span>
																			<span className="item__text-m">
																				{item.leadTime.start} -{" "}
																				{item.leadTime.end}{" "}
																			</span>
																		</div>
																	) : (
																		<div className="item__time">
																			<span className="item__text-m item__text-m--mx">
																				Попыток
																			</span>
																			<span className="item__text-m">
																				{item.attempts.start} -{" "}
																				{item.attempts.end}
																			</span>
																		</div>
																	)}
																	<span
																		className="item__edit"
																		onClick={() => editCourseHandle(item)}>
																		<img
																			className="item__edit-img"
																			src={editor}
																			alt=""
																		/>
																	</span>
																	<div className="item__bill">
																		<CheckBox
																			label="Платное"
																			isChecked={
																				isBillableCourse.includes(item._id)
																					? true
																					: false
																			}
																			onClick={() =>
																				handleSetBillableCourse(item._id)
																			}
																		/>
																	</div>
																</div>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								)}
							</DragDropContext>
						</div>
					</div>
				)}
			</div>
		</Dushboard>
	);
};
export default SortingActivities;
