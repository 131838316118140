import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { menuMusic } from "../../data/dataMunu.json";
import "./style.scss";
import Loader from "react-loader-spinner";
import { Message } from "../../fields";
import { useDispatch, useSelector } from "react-redux";
import { musicAction } from "../../../redux/actions/musicAction";
import { funcGet, funcGetById } from "../../../controllers";
import { allMusicAction } from "../../../redux/actions/allMusicAction";
import { useHistory } from "react-router-dom";
import List from "../../common/list";

const AllMusic = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const dispatch = useDispatch();
	const history = useHistory();
	const { allmusic, music } = useSelector((state) => state);
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [dataSearch, setDataSearch] = useState();

	useEffect(() => {
		getAllMusic();
	}, []);

	const getAllMusic = async () => {
		const res = await funcGet("melody", token);
		setDataSearch(res.data);
		dispatch(allMusicAction(res.data));
	};

	const handleEditor = async (id) => {
		const res = await funcGetById("melody", id, token);
		dispatch(
			musicAction({
				...music,
				title: res.data.title,
				audio: res.data.audio,
				text: res.data.text,
				category: res.data.category,
				viewYear: res.data.viewYear.join(),
				link: res.data.link,
			})
		);
		history.push("./music", { edit: true, id: id });
	};

	const getResult = (result) => {
		setDataSearch(result);
	};

	return (
		<Dushboard>
			<PageInnerMenu
				data={menuMusic}
				filterData={allmusic.items}
				getResult={getResult}
			/>
			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					{isStatus ? (
						<div className="lesson__message">
							<Message message={isMessage} status={isStatus} />
						</div>
					) : null}

					<div className="all-music">
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{allmusic.items.length > 0 ? (
										<h3 className="list-category__title">
											Все мелодии{" "}
											<span className="list-category__title--span">
												количество {allmusic.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все мелодии
										</h3>
									)}
								</div>
								<List data={dataSearch} hendleEditor={handleEditor} />
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default AllMusic;
