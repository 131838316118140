import axios from "axios";
import { localUrl, url } from "../api/config.http.json";

const funcGetById = async (path, id, token) => {
	console.log(`${url}/${path}/id?id=${id}`);
	const res = await axios({
		url: `${url}/${path}/id?id=${id}`,
		method: "get",
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return res;
};
export default funcGetById;
