import { UPLOAD_FILE } from "../actionTypes";

export const initUploadFile = {
	id: "",
	title: "",
	category: [],
	file: "",
};

export const uploadFile = (state = initUploadFile, action) => {
	switch (action.type) {
		case UPLOAD_FILE:
			return {
				...state,
				id: action.id,
				title: action.title,
				category: action.category,
				file: action.file,
			};

		default:
			return state;
	}
};
