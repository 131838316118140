import { EXPERTS } from "../actionTypes";

export const initExpert = {
	title: "",
	qualification: "",
	skills: "",
	avatar: "",
	image: "",
};

export const expert = (state = initExpert, action) => {
	switch (action.type) {
		case EXPERTS:
			return {
				...state,
				title: action.title,
				qualification: action.qualification,
				skills: action.skills,
				avatar: action.avatar,
				image: action.image,
			};

		default:
			return state;
	}
};
