import { BENEFITS, BENEFITS_UPDATE } from "../actionTypes";

export const initBenefits = {
	title: "",
	description: "",
	image: '',
	video: '',
	link: "",
	category: [],
	viewerMonth : ''
};

export const benefits = (state = initBenefits, action) => {
	switch (action.type) {
		case BENEFITS:
			return {
				...state,
				title: action.title,
				description: action.description,
				image: action.image,
				video: action.video,
				link: action.link,
				category: action.category,
				viewerMonth : action.viewerMonth
			};
		case BENEFITS_UPDATE:
			return {
				...state,
				title: action.title,
				description: action.description,
				image: action.image,
				video: action.video,
				link: action.link,
				category: action.category,
				viewerMonth : action.viewerMonth
			}
		default:
			return state;
	}
};