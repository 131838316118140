import React, { useState } from "react";
import checkin from "../../assets/svg/checkin.svg";

const CheckBox = ({id, label, isChecked, onClick}) => {
    return(
        <div className="checkBlock" onClick={onClick}>
            {
                isChecked
                ? <span className="checkBlock__checkbox checkBlock__checkbox--checked">
                    <img src={checkin} alt=""/>
                </span>
                : <span className="checkBlock__checkbox checkBlock__checkbox--unchecked"></span>
            }
            <span className="checkBlock__label" htmlFor={id}>{label}</span>
        </div>
    )
}
export default CheckBox;