import { ALL_BENEFITS } from "../actionTypes";

export const initAllBenefits = {
	items: [],
};

export const allbenefits = (state = initAllBenefits, action) => {
	switch (action.type) {
		case ALL_BENEFITS:
			return {
				...state,
				items: action.items,
			};

		default:
			return state;
	}
};
