import React, {useState} from "react";
import {Provider, useSelector} from "react-redux";
import {
    BrowserRouter,
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom";
import AllBenefits from "./components/pages/AllBenefits/AllBenefits";
import AllCategorys from "./components/pages/AllCategory";
import AllCourses from "./components/pages/AllCourses";
import AllCoursesPage from "./components/pages/AllCoursesPage/AllCoursesPage";
import AllExperts from "./components/pages/AllExperts";
import AllFiles from "./components/pages/AllFiles/AllFiles";
import AllMusic from "./components/pages/allMusic";
import AllNorms from "./components/pages/AllNorms";
import AllTexts from "./components/pages/AllTexts";
import Auth from "./components/pages/Auth/Auth";
import Benefits from "./components/pages/Benefits";
import Category from "./components/pages/Category";
import Course from "./components/pages/Course/Course";
import CoursesPage from "./components/pages/CoursesPage";
import Experts from "./components/pages/Experts";
import Files from "./components/pages/Files";
import Home from "./components/pages/Home";
import Lesson from "./components/pages/Lesson/Lesson";
import Lessons from "./components/pages/Lessons";
import Music from "./components/pages/Music";
import Norms from "./components/pages/Norms";
import SortingActivities from "./components/pages/SortingActivities";
import Text from "./components/pages/Text";
import ChildrenProfile from "./components/pages/childrenProfile";
import store from "./redux/store";

import "./style.scss";

const App = () => {
    return (
        <Provider store={store}>
            <div className="app-baby-course">
                <AppRoute/>
            </div>
        </Provider>
    );
};

const AppRoute = () => {
    const {user} = useSelector((state) => state);
    const starageToken = localStorage.getItem("token");
    let currentToken = "";

    starageToken === null
        ? (currentToken = user.token)
        : (currentToken = starageToken);

    return (
        <BrowserRouter>
            <PrivateRoute login={currentToken}/>
            <Route path="/login" component={Auth}/>
        </BrowserRouter>
    );
};

const PrivateRoute = ({login}) => {
    const location = useLocation();
    let path = "";
    location.pathname === "/ru" || location.pathname === "/ru/"
        ? (path = "/ru")
        : (path = "/");

    return (
        <>
            {login ? (
                <Switch>
                    <Route exact path={path} component={Home}/>
                    <Route exact path="/course-order" component={SortingActivities}/>
                    <Route path="/course" component={Course}/>
                    <Route path="/all-courses" component={AllCourses}/>
                    <Route path="/category" component={Category}/>
                    <Route path="/all-category" component={AllCategorys}/>
                    <Route path="/benefits" component={Benefits}/>
                    <Route path="/all-benefits" component={AllBenefits}/>
                    <Route path="/lesson" component={Lesson}/>
                    <Route path="/all-lesson" component={Lessons}/>
                    <Route path="/music" component={Music}/>
                    <Route path="/all-music" component={AllMusic}/>
                    <Route path="/text" component={Text}/>
                    <Route path="/all-texts" component={AllTexts}/>
                    <Route path="/norms" component={Norms}/>
                    <Route path="/all-norms" component={AllNorms}/>
                    <Route path="/files" component={Files}/>
                    <Route path="/all-files" component={AllFiles}/>
                    <Route path="/expert" component={Experts}/>
                    <Route path="/all-experts" component={AllExperts}/>
                    <Route path="/courses-page" component={CoursesPage}/>
                    <Route path="/all-courses-page" component={AllCoursesPage}/>
                    <Route path="/children-profile/:id" component={ChildrenProfile}/>
                </Switch>
            ) : (
                <Redirect to="/login"/>
            )}
        </>
    );
};

export default App;
