import PropTypes from "prop-types";
import React from "react";
import ReactSlider from "react-slider";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

const InputRange = ({ className, label, onChange }) => {

  return (
    <div className={className !== "" ? `${className}` : "input-range"}>
      <label
        className={
          className !== "" ? `${className}__label` : "input-range__label"
        }
      >
        {label}
      </label>
      <Nouislider 
      range={{ min: 0, max: 60 }} 
      start={[20, 40]} 
      connect={true}
      step={1.0}
      tooltips={true}
      onChange={(value) => onChange(value)}
      />
      {/* <ReactSlider
        className={"input-range__input"}
        thumbClassName="input-range__thumb"
        trackClassName="input-range__track"
        maxValue={60}
        minValue={0}
        defaultValue={[0, 60]}
        ariaLabel={["Lower thumb", "Upper thumb"]}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
        minDistance={1}
        onAfterChange={(val) => onAfterChange(val)}
      /> */}
    </div>
  );
};
InputRange.defaultProps = {
  className: "",
  label: "",
  onUpdate: () => null,
};
InputRange.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onUpdate: PropTypes.func,
};
export default InputRange;
