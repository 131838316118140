import { ALL_TEXT } from "../actionTypes";

export const initAllText = {
    items : []
}

export const alltexts = (state = initAllText, action) => {
    switch (action.type) {
            case ALL_TEXT:
                return{
                    ...state,
                    items: action.items
                }
        
            default: return state
        }
    
}