import { MUSIC } from "../actionTypes"

export const musicAction = (action) => {
    return{
        type: MUSIC,
        title: action.title,
        audio: action.audio,
        text: action.text,
        category: action.category,
        viewYear: action.viewYear,
        link: action.link,
    }
}