import Axios from "axios"
import { useCallback, useState } from "react"

export const useHttp = () => {
    const [loader, setLoader] = useState(false)
    const response = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
        setLoader(true)
        try {
            Axios.interceptors.response.use(function (response) {
                return response;
            }, function (error) {
                if (error.response) {
                    return Promise.reject(error.response);
                }
                return Promise.reject(error);
            });
            const res = await Axios({
                url: url,
                method: method,
                data: body,
                headers: headers
            })

            setLoader(false)
            return res.data
        } catch (res) {
            setLoader(false)
            return res.data
        }
    }, [])

    return {
        response,
        loader
    }
}