import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { menuText } from "../../data/dataMunu.json";
import "./style.scss";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Button, InputText, Message, Textarea, TitlePage } from "../../fields";
import ListSelect from "../../common/ListSelect";
import { textAction } from "../../../redux/actions/textAction";
import { funcCreate, funcDelete, funcEdit } from "../../../controllers";
import { useHistory } from "react-router-dom";
import { initText } from "../../../redux/reducers/text";
import { allCategoryAction } from "../../../redux/actions/allCategiryAction";
import ListSearch from "../../common/ListSearch";

const Text = ({ location }) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const dispatch = useDispatch();
	const history = useHistory();
	const { text, allcategory } = useSelector((state) => state);
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [selectItem, setSlectItem] = useState(text.category);
	const [visible, setVisible] = useState(false);
	const [isError, setError] = useState("");

	useEffect(() => {
		if (allcategory.items.length === 0) {
			dispatch(allCategoryAction({ token }));
		}
	}, []);

	useEffect(() => {
		setSlectItem(text.category);
	}, [text]);

	const process = (message, status) => {
		status ? setStatus(true) : setStatus(false);
		setLoader(false);
		setMessage(message);
	};

	const handleCategory = (item) => {
		!selectItem.includes(item)
			? setSlectItem((selectItem) => [...selectItem, item])
			: setSlectItem((selectItem) => selectItem.filter((el) => el !== item));
		setError("");
	};

	const saveHandler = async () => {
		const formData = new FormData();
		formData.append("title", text.title);
		formData.append("text", text.text);
		formData.append("video", text.video);
		formData.append("category", JSON.stringify(selectItem));
		formData.append("viewYear", text.viewYear);
		formData.append("link", text.link);
		try {
			setLoader(true);
			const res = await funcCreate(
				{ ...text, category: selectItem },
				"text",
				token
			);
			if (res.status === 201) {
				process("Успешно сохранено", true);
				resetData();
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			resetData();
		}
	};

	const editHandler = async () => {
		const formData = new FormData();
		formData.append("title", text.title);
		formData.append("text", text.text);
		formData.append("video", text.video);
		formData.append("category", JSON.stringify(selectItem));
		formData.append("viewYear", text.viewYear);
		formData.append("link", text.link);

		try {
			setLoader(true);
			const res = await funcEdit(
				formData,
				"text",
				token,
				location.state.id,
				"multipart/form-data"
			);
			if (res.status === 200) {
				process("Успешно сохранено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			resetData();
		}
	};

	const cancelingHandler = () => {
		history.goBack();
		resetData();
	};

	const deleteHandler = async () => {
		setLoader(true);
		const res = await funcDelete("text", token, location.state.id);
		if (res.status === 200) {
			process("Успешно удалено", true);
			resetData();
			setTimeout(() => {
				history.goBack();
			}, 1500);
		}
	};

	const resetData = () => {
		dispatch(textAction(initText));
	};

	return (
		<Dushboard>
			{location.state !== null && location.state.edit ? (
				<div className="wrapper-edit-button">
					<Button
						battonType="success-button"
						className="course-control"
						click={() => editHandler()}>
						Сохранить
					</Button>
					<Button
						battonType="light-button"
						className="course-control"
						click={() => cancelingHandler()}>
						Отменить
					</Button>
					<Button
						battonType="dark-button"
						className="course-control"
						click={() => deleteHandler()}>
						Удалить
					</Button>
				</div>
			) : (
				<PageInnerMenu data={menuText} />
			)}

			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					<div className="music">
						{isStatus ? (
							<div className="lesson__message">
								<Message message={isMessage} status={isStatus} />
							</div>
						) : null}
						<TitlePage tag="h2" title="Текст" />
						<div className="row">
							<div className="col-6 col-l-12 col--m">
								<InputText
									label="Название текста"
									placeholder="введите название"
									name="title-text"
									type="text"
									value={text.title}
									onChange={(val) => {
										process("", false);
										dispatch(textAction({ ...text, title: val }));
									}}
								/>

								<Textarea
									label={"Текст"}
									value={text.text}
									onChange={(val) =>
										dispatch(textAction({ ...text, text: val }))
									}
								/>
								<InputText
									label="Ссылка на текст"
									placeholder="https://"
									name="title-link"
									type="text"
									value={text.link}
									onChange={(val) =>
										dispatch(textAction({ ...text, link: val }))
									}
								/>
								<InputText
									label="Видео"
									placeholder="ID"
									type="text"
									value={text.video}
									onChange={(val) =>
										dispatch(textAction({ ...text, video: val }))
									}
								/>
								<div className="music-wrap-list">
									<ListSearch
										label="Категории"
										isVisible={visible}
										selectedItems={selectItem}
										data={allcategory.items.filter(
											(i) => i.typeCategory === "Тексты"
										)}
										onShow={() => setVisible(!visible)}
										onChange={handleCategory}
									/>
								</div>

								<div className="music__button-wrap">
									{location.state !== null && location.state.edit ? null : (
										<Button
											battonType="success-button"
											className="course-control"
											click={() => saveHandler()}>
											Сохранить
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default Text;
