import { NORMS } from "../actionTypes";

export const initNomrs = {
    title: '',
    viewMonth: '',
    category: [],
}

export const norms = (state = initNomrs, action) => {
    switch (action.type) {
        case NORMS:
            return {
                ...state,
                title: action.title,
                viewMonth: action.viewMonth,
                category: action.category,
            }
    
        default: return state
    }
}