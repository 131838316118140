import React, { useState } from "react";
import Fuse from "fuse.js";
import InputText from "../../fields/InputText";
import "./style.scss";

const Search = ({ data, getResult }) => {
	const [search, setSearch] = useState("");

	// ======= search =========

	const options = {
		shouldSort: true,
		tokenize: true,
		matchAllTokens: true,
		findAllMatches: true,
		threshold: 0,
		location: 0,
		distance: 0,
		maxPatternLength: 32,
		minMatchCharLength: 1,
		keys: ["title", "name"],
	};

	const fuse = new Fuse(data, options);

	const hendleSearch = async (value) => {
		setSearch(value);
		const result = fuse.search(value).map((r) => r.item);
		value === "" ? getResult(data) : getResult(result);
	};

	// ======= search =========
	return (
		<div className="search">
			<InputText
				value={search}
				placeholder="Поиск"
				className="search"
				onChange={(val) => hendleSearch(val)}
			/>
			<button
				className="search__reset"
				onClick={() => {
					setSearch("");
					getResult(data);
				}}>
				Очистить
			</button>
		</div>
	);
};

export default Search;
