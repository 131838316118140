import PropTypes from "prop-types";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Logo } from "../../fields";

import "./style.scss";

const Header = () => {
	let history = useHistory();
	const handlerExit = () => {
		localStorage.removeItem("token");
		history.push("/login");
	};
	return (
		<header className="header">
			<div className="logo-header">
				<Logo className="logo" />
			</div>
			<div className="header-account">
				<div className="header-account__image-wrap">
					<span className="header-account__image"></span>
				</div>
				{/* <InputSelectCustom
					className="header-account-select"
					data={dataSelectHeader.items}
					onChange={() => alert("ok")}
				/> */}
				<Button battonType="dark-button" click={() => handlerExit()}>
					Выход
				</Button>
			</div>
		</header>
	);
};
Header.defaultProps = {
	menu: [],
};
Header.propTypes = {
	menu: PropTypes.array,
};
export default Header;
