import { TEXT } from "../actionTypes";

export const initText = {
	title: "",
	text: "",
	video: "",
	category: [],
	viewYear: [],
	link: "",
};

export const text = (state = initText, action) => {
	switch (action.type) {
		case TEXT:
			return {
				...state,
				title: action.title,
				text: action.text,
				video: action.video,
				category: action.category,
				viewYear: action.viewYear,
				link: action.link,
			};

		default:
			return state;
	}
};
