import { USER } from "../actionTypes";

const initState = {
    token: '',
    id: '',
    login: false
}

export const user = (state = initState, action) => {
    switch (action.type) {
        case USER:
            return {
                ...state,
                token: action.token,
                id: action.id,
                login: action.login
            }
        default: return state
    }
}