import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { funcGet, funcGetById } from "../../../controllers";
import { AllNormActions } from "../../../redux/actions/allNormsAction";
import { normAction } from "../../../redux/actions/normsAction";
import { initNomrs } from "../../../redux/reducers/norms";
import List from "../../common/list";
import PageInnerMenu from "../../common/pageInnerMenu";
import { menuNorm } from "../../data/dataMunu.json";
import { Message } from "../../fields";
import Dushboard from "../../layout/Dushboard";
import "./style.scss";

const AllNorms = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const dispatch = useDispatch();
	const history = useHistory();
	const { allnorms, norms } = useSelector((state) => state);
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [dataSearch, setDataSearch] = useState();

	useEffect(() => {
		getAllNorm();
	}, []);

	const getAllNorm = async () => {
		const res = await funcGet("norm", token);
		if (res.status > 400) {
			afterFetch(false);
		}
		setDataSearch(res.data);
		dispatch(AllNormActions(res.data));
	};

	const handleEditor = async (id) => {
		try {
			const res = await funcGetById("norm", id, token);
			if (res.status === 200) {
				dispatch(
					normAction({
						...norms,
						title: res.data.title,
						viewMonth: res.data.viewMonth.join(","),
						category: res.data.category,
					})
				);
				history.push("./norms", { edit: true, id: id });
			}
		} catch (error) {}
	};

	const afterFetch = (status) => {
		if (status === true) {
			setLoader(false);
			setStatus(true);
			setMessage("Успешно сохранено.");
		} else {
			setLoader(false);
			setStatus(false);
			setMessage("Что то пошло не так, попробуйте позже.");
		}
		dispatch(normAction(initNomrs));
	};

	const getResult = (result) => {
		setDataSearch(result);
	};

	return (
		<Dushboard>
			<PageInnerMenu
				data={menuNorm}
				filterData={allnorms.items}
				getResult={getResult}
			/>
			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					{isStatus ? (
						<div className="lesson__message">
							<Message message={isMessage} status={isStatus} />
						</div>
					) : null}

					<div className="all-music">
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{allnorms.items.length > 0 ? (
										<h3 className="list-category__title">
											Все нормы развития{" "}
											<span className="list-category__title--span">
												количество {allnorms.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все нормы развития
										</h3>
									)}
								</div>
								<List data={dataSearch} hendleEditor={handleEditor} />
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};

export default AllNorms;
