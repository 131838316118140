import { COURSES_PAGE } from "../actionTypes";

export const coursesAction = (action) => {
	return {
		type: COURSES_PAGE,
		title: action.title,
		image: action.image,
		viewerYear: action.viewerYear,
		price: action.price,
		bill: action.bill,
		description: action.description,
		expertsList: action.expertsList,
		category: action.category,
	};
};
