import React, { useEffect, useState } from "react";
import "./style.scss";
import { menuCategory } from "../../data/dataMunu.json";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { funcGet, funcGetById } from "../../../controllers";
import Dushboard from "../../layout/Dushboard";
import PageInnerMenu from "../../common/pageInnerMenu";
import Loader from "react-loader-spinner";
import { Message } from "../../fields";
import List from "../../common/list";
import { categoryAction } from "../../../redux/actions/categoryAction";
import { allCategoryAction } from "../../../redux/actions/allCategiryAction";
import Search from "../../common/search/Search";

const AllCategorys = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const dispatch = useDispatch();
	const history = useHistory();

	const { allcategory } = useSelector((state) => state);
	const [category, setCategory] = useState();
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();

	useEffect(() => {
		getAll();
	}, []);

	const getAll = async () => {
		const res = await funcGet("category", token);
		setCategory(res.data);
		dispatch(allCategoryAction({ token }));
	};

	const handleEditor = async (id) => {
		const res = await funcGetById("category", id, token);
		dispatch(categoryAction(res.data[0]));
		history.push("./category", { edit: true, id: id });
	};

	const getResult = (result) => {
		setCategory(result);
	};

	return (
		<Dushboard>
			<PageInnerMenu
				data={menuCategory}
				filterData={allcategory.items}
				getResult={getResult}
			/>
			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					{isStatus ? (
						<div className="lesson__message">
							<Message message={isMessage} status={isStatus} />
						</div>
					) : null}

					<div className="all-music">
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{allcategory.items.length > 0 ? (
										<h3 className="list-category__title">
											Все категории{" "}
											<span className="list-category__title--span">
												количество {allcategory.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все категории
										</h3>
									)}
								</div>
								<List data={category} hendleEditor={handleEditor} />
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default AllCategorys;
