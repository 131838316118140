import PropTypes from "prop-types";
import React, {useState} from "react";
import {BlockPicker, MaterialPicker} from "react-color";
import "./style.scss";

const ColorPicker = ({defaultColor, callback}) => {
    const [background, setBackground] = useState(defaultColor);
    const [isVisible, setVisible] = useState(false);

    const handleChangeComplete = (colors) => {
        setBackground(colors.hex);
        callback(colors.hex);
    };

    const handleInput = (val) => {
        setBackground(val);
    };
    return (
        <div className="color-picker">
            <div className="color-picker__inputs">
                <span
                    className="color-picker__output"
                    style={{backgroundColor: background}}
                    onClick={() => setVisible(!isVisible)}/>
                {/*<input*/}
                {/*    type="text"*/}
                {/*    className="color-picker__input"*/}
                {/*    value={background}*/}
                {/*    onChange={(e) => handleInput(e.target.value)}*/}
                {/*/>*/}

                {/*<BlockPicker*/}
                {/*    width={220}*/}
                {/*    color={background}*/}
                {/*    onChangeComplete={handleChangeComplete}*/}
                {/*/>*/}
                <MaterialPicker
                    width={220}
                    color={background}
                    onChangeComplete={handleChangeComplete}
                />
                {/*<span*/}
                {/*    className="color-picker__close"*/}
                {/*    onClick={() => setVisible(!isVisible)}/>*/}
            </div>
            {/*{isVisible ? (*/}
            {/*    <>*/}
            {/*        <BlockPicker*/}
            {/*            width={220}*/}
            {/*            color={background}*/}
            {/*            onChangeComplete={handleChangeComplete}*/}
            {/*        />*/}
            {/*        <span*/}
            {/*            className="color-picker__close"*/}
            {/*            onClick={() => setVisible(!isVisible)}/>*/}
            {/*    </>*/}
            {/*) : null}*/}
        </div>
    );
};
ColorPicker.defaultProps = {
    defaultColor: "",
};

ColorPicker.propTypes = {
    defaultColor: PropTypes.string,
};

export default ColorPicker;
