import React, { useCallback, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PageInnerMenu from "../../common/pageInnerMenu";
import { Button, InputText, Message, TitlePage } from "../../fields";
import Dushboard from "../../layout/Dushboard";
import { menuNorm } from "../../data/dataMunu.json";
import "./style.scss";
import { normAction } from "../../../redux/actions/normsAction";
import ListSelect from "../../common/ListSelect";
import funcCreate from "../../../controllers/create";
import { initNomrs } from "../../../redux/reducers/norms";
import { funcDelete, funcEdit } from "../../../controllers";
import ListSearch from "../../common/ListSearch";

const Norms = ({ location }) => {
	const token = JSON.parse(localStorage.getItem("token"));

	const { norms, allcategory } = useSelector((state) => state);
	const dispatch = useDispatch();
	const history = useHistory();
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [selectItems, setsSlectItems] = useState(norms.category);
	const [visibleCat, setVisibleCat] = useState(false);

	const handleCategory = (item) => {
		!selectItems.includes(item)
			? setsSlectItems((selectItems) => [...selectItems, item])
			: setsSlectItems((selectItems) =>
					selectItems.filter((el) => el !== item)
			  );
	};

	const process = (message, status) => {
		status ? setStatus(true) : setStatus(false);
		setLoader(false);
		setMessage(message);
	};

	// ============= Запросы на сервер ======
	const saveHandler = async () => {
		setLoader(true);
		try {
			const res = await funcCreate(
				{ ...norms, category: selectItems },
				"norm",
				token
			);
			if (res.status === 201) {
				process("Успешно сохранено", true);
				resetData();
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			resetData();
		}
	};

	const editHandler = async () => {
		try {
			const res = await funcEdit(
				{ ...norms, category: selectItems },
				"norm",
				token,
				location.state.id
			);
			if (res.status === 200) {
				process("Успешно сохранено", true);
				resetData();
				setTimeout(() => {
					history.goBack();
				}, 1500);
			} else {
				process("Что то пошло не так", true);
			}
		} catch (error) {
			resetData();
		}
	};
	const cancelingHandler = async () => {
		history.goBack();
		dispatch(normAction(initNomrs));
	};
	const deleteHandler = async () => {
		const res = await funcDelete("norm", token, location.state.id);
		if (res.status === 200) {
			process("Успешно удалено", true);
			resetData();
			setTimeout(() => {
				history.goBack();
			}, 1500);
		}
	};

	const resetData = () => {
		dispatch(normAction(initNomrs));
		setsSlectItems([]);
		setLoader(false);
	};

	return (
		<Dushboard>
			{location.state !== null && location.state.edit ? (
				<div className="wrapper-edit-button">
					<Button
						battonType="success-button"
						className="course-control"
						click={() => editHandler()}>
						Сохранить
					</Button>
					<Button
						battonType="light-button"
						className="course-control"
						click={() => cancelingHandler()}>
						Отменить
					</Button>
					<Button
						battonType="dark-button"
						className="course-control"
						click={() => deleteHandler()}>
						Удалить
					</Button>
				</div>
			) : (
				<PageInnerMenu data={menuNorm} />
			)}

			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					<div className="norms">
						{isStatus ? (
							<Message message={isMessage} status={isStatus} />
						) : null}
						<TitlePage tag="h2" title="Нормы развития" />
						<div className="row">
							<div className="col-6 col-l-12 col--m">
								<div className="norms__item">
									<InputText
										label="Название"
										value={norms.title}
										placeholder="введите название"
										onChange={(val) => {
											process("", false);
											dispatch(normAction({ ...norms, title: val }));
										}}
									/>
								</div>
								<div className="norms__item">
									<ListSearch
										label="Категории"
										isVisible={visibleCat}
										reset={norms.category}
										selectedItems={selectItems}
										data={allcategory.items}
										onShow={() => setVisibleCat(!visibleCat)}
										onChange={handleCategory}
									/>
								</div>
								<div className="norms__item">
									<InputText
										label="Месяц показа"
										value={norms.viewMonth}
										placeholder="1,2,3"
										onChange={(val) =>
											dispatch(normAction({ ...norms, viewMonth: val }))
										}
									/>
								</div>
								<div className="music__button-wrap">
									{location.state !== null && location.state.edit ? null : (
										<Button
											battonType="success-button"
											className="course-control"
											click={() => saveHandler()}>
											Сохранить
										</Button>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default Norms;
