import { CAT } from "../actionTypes";

export const initCategory = {
	title: "",
	image: "",
	imageWhite: "",
	typeCategory: "",
	color: "#2ccce4",
};

export const category = (state = initCategory, action) => {
	switch (action.type) {
		case CAT:
			return {
				...state,
				title: action.title,
				image: action.image,
				imageWhite: action.imageWhite,
				typeCategory: action.typeCategory,
				color: action.color,
			};

		default:
			return state;
	}
};
