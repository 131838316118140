import { LECTURE_BY_DAYS } from "../actionTypes";

export const initLectureByDay = {
	items: [],
};

export const lectureByDays = (state = initLectureByDay, action) => {
	switch (action.type) {
		case LECTURE_BY_DAYS:
			return {
				...state,
				items: action.items,
			};
		default:
			return state;
	}
};
