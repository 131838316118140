import React from "react";
import ReactFileReader from "react-file-reader";
import Image from "../common/image";
import { Button } from "../fields";
import "./style.scss";

const ImageBlock = ({
	files,
	src = "",
	uploadHandler,
	text = "Загрузите иконку категории",
}) => {
	return (
		<div className="image-block">
			<div className="image-block__image--btn">
				<ReactFileReader
					base64={true}
					multipleFiles={false}
					handleFiles={uploadHandler}>
					<Button className="image-block" battonType="success-button">
						{text}
					</Button>
				</ReactFileReader>
			</div>
			<div className="image-block__image--wrap">
				<Image
					maxWidth={220}
					src={files.base64 !== "" ? files.base64 : src}
					//click={uploadHandler}
				/>
			</div>
		</div>
	);
};
export default ImageBlock;
