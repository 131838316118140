import axios from "axios";
import { localUrl, url } from "../api/config.http.json";
import moment from "moment";

const funcAuth = async () => {
	const currentMoment = moment().format("DD/MM/YYYY");
	const user = localStorage.getItem("user");

	const res = await axios({
		url: `${url}/auth/login`,
		method: "post",
		data: JSON.parse(user),
	});

	const lastVisit = {
		token: res.data.token,
		lastVisit: currentMoment,
	};
	localStorage.setItem("auth", JSON.stringify(lastVisit));
	localStorage.setItem("token", JSON.stringify(res.data.token));

	return lastVisit;
};
export default funcAuth;
