import axios from "axios";
import { localUrl, url } from "../api/config.http.json";

const funcDelete = async (path, token, id) => {
	const res = await axios({
		url: `${url}/${path}/delete?id=${id}`,
		method: "delete",
		data: null,
		headers: {
			Authorization: token,
			"Content-Type": "application/json",
		},
	});

	return res;
};
export default funcDelete;
