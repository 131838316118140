import React, { useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";
import "./style.scss";
import close from "../../../assets/images/close.png";

const ListSearch = ({ label, selectedItems = [], data, onChange }) => {
	const [search, setSearch] = useState("");
	const [searchArr, setSearchgArr] = useState(data);
	const options = {
		shouldSort: true,
		tokenize: true,
		matchAllTokens: true,
		findAllMatches: true,
		threshold: 0,
		location: 0,
		distance: 0,
		maxPatternLength: 32,
		minMatchCharLength: 1,
		keys: ["title"],
	};

	const fuse = new Fuse(data, options);

	const hendleSearch = async (value) => {
		setSearch(value);
		const result = fuse.search(value).map((r) => r.item);

		if (value === "") {
			setSearchgArr(data);
		} else {
			setSearchgArr(result);
		}
	};
	useEffect(() => {
		setSearchgArr(data);
	}, [data]);

	const renderItems = useMemo(() => {
		return searchArr.map((item, idx) => {
			return (
				<li
					key={`${item.title}-${idx}`}
					className="list-select__item"
					onClick={() => onChange(item.title)}>
					<span className="list-select__title">{item.title}</span>
					<span className="list-select__categoty-type">
						{"typeCategory" in item && `Тип: ${item.typeCategory}`}
					</span>
					<span
						className={
							selectedItems.includes(item.title)
								? "list-select__checkbox list-select__checkbox--active"
								: "list-select__checkbox"
						}></span>
				</li>
			);
		});
	}, [data, searchArr, selectedItems]);
	return (
		<div className="list-select">
			<span className="list-select__label">{label}</span>
			<div className="search-wrapper">
				<input
					type="text"
					value={search}
					className="search-item"
					onChange={(e) => hendleSearch(e.target.value)}
				/>
			</div>
			<ul className="list-select__list">{renderItems}</ul>
			<div className="list-select__selected-items">
				{selectedItems.map((i) => (
					<span
						key={i}
						className="list-select__selected-item"
						onClick={() => onChange(i)}>
						{i}
						<img className="list-select__close" src={close} alt="" />
					</span>
				))}
			</div>
		</div>
	);
};
export default ListSearch;
