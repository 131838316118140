import axios from "axios";
import { url } from "../../api/config.http.json";
import { ALL_BENEFITS } from "../actionTypes";

export const allBenefitsAction = action => dispatch => {
    axios({
        url: `${url}/benefits`,
        method: "get",
        headers: {
            Authorization: action.token,
            "Content-Type": "application/json",
        },
    }).then(res => {
        dispatch({type : ALL_BENEFITS, items : res.data})
    })

}