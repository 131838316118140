import { ALL_EXPERTS } from "../actionTypes";
export const initAllExperts = {
	items: [],
};
export const allexperts = (state = initAllExperts, action) => {
	switch (action.type) {
		case ALL_EXPERTS:
			return {
				...state,
				items: action.items,
			};

		default:
			return state;
	}
};
