import React, { useEffect, useState } from "react";
import PageInnerMenu from "../../common/pageInnerMenu";
import Dushboard from "../../layout/Dushboard";
import { filesMenu } from "../../data/dataMunu.json";
import "./style.scss";
import Loader from "react-loader-spinner";
import { Message } from "../../fields";
import { useDispatch, useSelector } from "react-redux";
import { musicAction } from "../../../redux/actions/musicAction";
import { funcGet, funcGetById } from "../../../controllers";
import { useHistory } from "react-router-dom";
import List from "../../common/list";
import { allFilesAction } from "../../../redux/actions/allFilesUpload";
import { uploadFileAction } from "../../../redux/actions/uploadFileAction";

const AllFiles = () => {
	const token = JSON.parse(localStorage.getItem("token"));
	const dispatch = useDispatch();
	const history = useHistory();
	const { allfiles, uploadFile } = useSelector((state) => state);
	const [isMessage, setMessage] = useState("");
	const [isStatus, setStatus] = useState();
	const [loader, setLoader] = useState();
	const [dataSearch, setDataSearch] = useState();

	useEffect(() => {
		getAllFiles();
	}, []);

	const getAllFiles = async () => {
		const res = await funcGet("fileload", token);
		setDataSearch(res.data);
		dispatch(allFilesAction(res.data));
	};

	const handleEditor = async (id) => {
		const res = await funcGetById("fileload", id, token);
		dispatch(
			uploadFileAction({
				...uploadFile,
				id: res.data._id,
				title: res.data.title,
				category: res.data.category,
				file: res.data.file,
			})
		);
		history.push("./files", { edit: true, id: id });
	};

	const getResult = (result) => {
		setDataSearch(result);
	};

	return (
		<Dushboard>
			<PageInnerMenu
				data={filesMenu}
				filterData={allfiles.items}
				getResult={getResult}
			/>
			{loader ? (
				<div className="loader-wrap">
					<Loader type="Puff" color="#00BFFF" height={50} width={50} />
				</div>
			) : (
				<>
					{isStatus ? (
						<div className="lesson__message">
							<Message message={isMessage} status={isStatus} />
						</div>
					) : null}

					<div className="all-music">
						<div className="row">
							<div className="col-12 col-m-6">
								<div className="list-category">
									{allfiles.items.length > 0 ? (
										<h3 className="list-category__title">
											Все файлы{" "}
											<span className="list-category__title--span">
												количество {allfiles.items.length}
											</span>
										</h3>
									) : (
										<h3 className="list-category__title">
											Здесь будут показаны все файлы
										</h3>
									)}
								</div>
								<List data={dataSearch} hendleEditor={handleEditor} />
							</div>
						</div>
					</div>
				</>
			)}
		</Dushboard>
	);
};
export default AllFiles;
