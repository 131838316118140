import { UPLOAD_ALL_FILE } from "../actionTypes";

export const initFilesUpload = {
	items: [],
};
export const allfiles = (state = initFilesUpload, action) => {
	switch (action.type) {
		case UPLOAD_ALL_FILE:
			return {
				...state,
				items: action.items,
			};

		default:
			return state;
	}
};
